export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyBrJaiS8uqXVO4gOg60bp63ZV3tcmR_sCU",
    authDomain: "fibie-b726e.firebaseapp.com",
    databaseURL: "https://fibie-b726e.firebaseio.com",
    projectId: "fibie-b726e",
    storageBucket: "fibie-b726e.appspot.com",
    messagingSenderId: "387991783275",
    appId: "1:387991783275:web:f2581e979f5f9a83372e2e",
    measurementId: "G-C4KYLYDWHJ",
  },
  aws: {
    credentials: {
      accessKeyId: "AKIAIDS7R3LQFJHD5TPA",
      secretAccessKey: "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",
      region: "ap-southeast-2",
    },
    s3bucket: "fibie-content",
  },
  googleMaps: {
    key: "AIzaSyC1ZaWXJqv1YHJqSD8n534jnGNdYdYsqvE",
    countryRestrictions: ["au"],
  },
  algolia: {
    appId: "VH747KOJA6",
    apiKey: "645c850d67f5f2eb8ca35889b5e4baa9",
  },
  directus: {
    uri: "https://cms.fibievents.com/fibie",
    accessToken: "faHt8aRYfCsvXnnyGa83c2W4",
  },
  unsplash: {
    uri: "https://api.unsplash.com/",
    clientId:
      "4e5685b2d4262c63a4e7b4e7715df86ca52257400577b9dd8811669d6a9af7c0",
  },
  obe: {
    uri: "https://obe-api-test.ozaccom.com.au/",
    places: {
      uri: "https://dev-obe-hotels-api.ozaccom.com.au/",
    },
  },
  fibieOptions: {
    fees: 0.01,
    maxProperties: 6,
  },
};
