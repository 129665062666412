
import {map} from 'rxjs/operators';
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  ChangeDetectorRef
} from "@angular/core";
import { NgForm } from "@angular/forms";
import { Location } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import {
  AngularFirestore,
  AngularFirestoreCollection
} from "angularfire2/firestore";
import { AuthService } from "../../core/auth/auth.service";
import { Event } from "../../types/event";
import { Tickets } from "../../types/tickets";
import { Registration } from "../../types/registration";
import { DateService } from "../../core/date.service";

declare var $: any;

import * as _ from "lodash";
import * as moment from "moment";

@Component({
  selector: "app-book",
  templateUrl: "./book.component.html",
  styleUrls: ["./book.component.scss"]
})
export class BookComponent implements OnInit {
  //@ViewChild("cardnumberf") cardnumberf: ElementRef;
  // @ViewChild("cardcvc") cardcvc: ElementRef;
  // @ViewChild("cardexpiry") cardexpiry: ElementRef;
  @ViewChild("cardInfo") cardInfo: ElementRef;

  notFound: boolean = false;
  slug: string;
  private sub: any;

  eventDetails: Event;
  eventRef: any;
  tickets: any[];

  registration: any = new Registration();

  card: any;
  cardHandler = this.onChange.bind(this);
  error: string;

  timerEnd: Date = new Date();
  timer: string = "calculating";

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private afs: AngularFirestore,
    public auth: AuthService,
    private cd: ChangeDetectorRef,
    private _location: Location,
    private dateService: DateService
  ) {}

  ngOnInit() {
    this.timerEnd = new Date(this.timerEnd.getTime() + 20 * 60 * 1000); //20 mins yo
    let that = this;
    let counter = setInterval(function() {
      //that.timer = moment().to(that.timerEnd);
      let remains = moment.duration(moment(that.timerEnd).diff(new Date()));
      if (remains.minutes() > 0) {
        that.timer = `${remains.minutes()} mins ${remains.seconds()} secs`;
      } else {
        that.timer = `${remains.seconds()} secs`;
      }
      if (moment(that.timerEnd).diff(new Date()) <= 0) {
        clearInterval(counter);
        that._location.back();
      }
    }, 1000);

    this.sub = this.route.params.subscribe(params => {
      this.slug = params["slug"];
      this.getEvent();
    });
    this.mapUserDetails();
  }

  getPrice(amount: Number) {
    if (amount > 0) return "$" + amount.toFixed(2);
    return "Free";
  }

  mapUserDetails() {
    this.auth.user.subscribe(user => {
      if (user) {
        this.registration.email = user.email;
        this.registration.firstname = user.displayName.split(" ")[0];
        this.registration.lastname = user.displayName.split(" ")[1];
      } else {
        //TESTING ONLY (REMOVE THIS)
        this.registration = {
          address: {
            // country: "Australia",
            // state: "Queensland",
            // address1: "38 Laidlaw Parade",
            // city: "East Brisbane",
            // postcode: "4169"
          },
          //payment: { cardname: "STEVE GREHAN", cardnumber: "4444 3333 2222 1111", cardexpiry: "02 / 2019", cardcvc: "321" },
          tickets: [],
          email: "",
          phone: "",
          firstname: "",
          lastname: ""
        };
      }
    });
  }

  getEvent() {
    this.afs
      .collection<Event>("events", ref => ref.where("slug", "==", this.slug))
      .snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as any;
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      }))
      .subscribe(data => {
        if (data.length > 0) {
          this.eventDetails = data[0];
          this.afs
            .collection(`/events/${this.eventDetails.id}/tickets`, ref =>
              ref.where("active", "==", true).orderBy("order", "asc")
            )
            .valueChanges()
            .subscribe(ticketdata => {
              if (ticketdata) this.tickets = ticketdata;
              this.cardControls();
              this.formValidation();
            });
        } else {
          //404
          this.notFound = true;
        }
      });
  }

  getMaxTickets(maxTickets) {
    let available = [];
    if (!maxTickets) maxTickets = 99; //default to 99 for now;
    for (let y = 0; y <= maxTickets; y++) {
      available.push(y);
    }
    return available;
  }

  ticketChange(e, ticket) {
    let qty = new Number(e.target.value);
    let i = _.findIndex(this.registration.tickets, function(iticket) {
      return iticket.ticket.id == ticket.id;
    });
    if (i < 0) {
      if (qty > 0) {
        //add to cart
        this.registration.tickets.push({ ticket, qty: qty });
      } else {
        //remove from cart
        this.registration.tickets = this.registration.tickets.splice(i, 1);
      }
    } else {
      //update cart
      this.registration.tickets[i].qty = qty;
    }
  }

  total() {
    let obj = {
      numberOfTickets: 0,
      taxDescription: "GST",
      discount: 0,
      tax: 0,
      amount: 0
    };

    _.forEach(this.registration.tickets, function(ticket) {
      obj.amount += ticket.ticket.price * ticket.qty;
      obj.numberOfTickets += ticket.qty;
    });

    obj.tax = obj.amount / 11; //gst for now

    return obj;
  }

  cardControls() {
    // $(this.cardnumber.nativeElement).payment("formatCardNumber");
    // $(this.cardcvc.nativeElement).payment("formatCardCVC");
    // $(this.cardexpiry.nativeElement).payment("formatCardExpiry");
    // var classes = {
    //   base: "form-element"
    // };
    // var cardNumber = elements.create("cardNumber", {
    //   classes: classes
    // });
    // cardNumber.mount(this.cardnumberf.nativeElement);

    if (!this.eventDetails.freeOptions) {
      this.card = elements.create("card", { hidePostalCode: true });
      this.card.mount(this.cardInfo.nativeElement);
      this.card.addEventListener("change", this.cardHandler);

      $(this.cardInfo.nativeElement).prepend(
        '<label class="floating-label">Your Card Details</label>'
      );
    }
  }

  formValidation() {
    var forms = document.getElementsByClassName("needs-validation");
    var validation = Array.prototype.filter.call(forms, function(form) {
      form.addEventListener(
        "submit",
        function(event) {
          if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
          }
          form.classList.add("was-validated");
        },
        false
      );
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
    if (!this.eventDetails.freeOptions) {
      this.card.removeEventListener("change", this.cardHandler);
      this.card.destroy();
    }
  }

  onChange({ error }) {
    if (error) {
      this.error = error.message;
    } else {
      this.error = null;
    }
    this.cd.detectChanges();
  }

  async buyTickets(form: NgForm) {
    if (form.valid) {
      if (!this.eventDetails.freeOptions) {
        console.log("this.card:", this.card);
        const { token, error } = await stripe.createToken(this.card);
        console.log("Success!", token);
      }

      $("#payModal").modal("show");
      setTimeout(() => {
        const id = this.afs.createId();
        this.registration.id = id;
        this.registration.status = "COMPLETE";
        this.registration.tickets = [];
        console.log("this.eventDetails.id:", this.eventDetails.id);
        this.registration = Object.assign({}, this.registration); //paymentSetup = Object.assign({}, this.event.paymentSetup)
        this.registration.eventRef = this.afs.doc(
          `events/${this.eventDetails.id}`
        ).ref;
        this.registration.address = [];
        console.log("this.registration:", this.registration);

        //save registration
        this.afs
          .collection("registrations")
          .doc(id)
          .set(this.registration);

        //update payment details and status

        //if status is ok forward to confirmation
        this.router.navigate(["../confirm", { id: id }], {
          relativeTo: this.route
        });

        //else hide modal and ask for more details
        $("#payModal").modal("hide");
      }, 1000);
    }
  }
}
