import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Upload, UploadService } from "../../services/upload.service";

@Component({
  selector: "drop-uploader",
  templateUrl: "./dropuploader.component.html",
  styleUrls: ["./dropuploader.component.scss"]
})
export class DropUploaderComponent {
  @Input() imageDetails: any;
  @Input() controlId: string = "0";
  @Output() uploaded = new EventEmitter();

  imageUpload: string =
    "imageUpload_" +
    Math.random()
      .toString(36)
      .substring(2); //this.controlId;

  loadingImage: boolean = false;
  dropzoneActive: boolean = false;

  constructor(private upload: UploadService) {}

  dropzoneState($event: boolean) {
    this.dropzoneActive = $event;
  }

  handleDrop(fileList: FileList) {
    if (fileList.length == 1) {
      this.handleUpload(fileList[0]);
    }
  }

  fileEvent(fileInput: any) {
    const file = fileInput.target.files[0];
    this.handleUpload(file);
  }

  handleUpload(file: any) {
    this.loadingImage = true;

    let upload = new Upload(file, this.imageDetails.s3name + file.name);
    this.upload
      .pushFile(upload)
      .then(res => {
        this.imageDetails.url = res.url;
        this.uploaded.emit(res.url);
        this.loadingImage = false;
      })
      .catch(err => {
        console.log("err:", err);
        this.loadingImage = false;
      });
  }
}
