export class UserPreferences {
  about?: string = "";
  backgroundColor?: string = "";
  backgroundImage?: string = "";
}

export class UserBanking {
  accountName?: string = "";
  accountBSB?: string = "";
  accountNumber?: string = "";
  updated?: Date = new Date();
}

export class UserEmailPreferences {
  emailAttendeeFeatures?: boolean = true;
  emailAttendeeUpcoming?: boolean = false;
  emailAttendeeReviews?: boolean = false;
  emailOrganiserFeatures?: boolean = true;
  emailOrganiserTips?: boolean = false;
  //emailOrganiserSales?: boolean = false;
}

export class User {
  uid: string;
  email: string;
  photoURL?: string;
  displayName?: string;
  premium?: boolean;
  preferences?: UserPreferences;
  emailPreferences?: UserEmailPreferences;
  banking?: UserBanking;
}
