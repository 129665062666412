
import {filter} from 'rxjs/operators';
import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { environment } from "./../../../environments/environment";

import { AngularFirestore } from "angularfire2/firestore";

import { Event } from "../../types/event";

import * as _ from "lodash";

@Component({
  selector: "app-search",
  templateUrl: "./search.component.html",
  styleUrls: ["./search.component.scss"]
})
export class SearchComponent implements OnInit {
  autoFocus: boolean = false;
  topResults: boolean = false;
  showResults = true;
  headerStyle: any = {};

  //for beautiful header
  searchInfo: any = {
    area: "your local area",
    category: "Things to do"
  };

  //special pages
  specialHeaderInfo: any = {
    useSpecial: false,
    title: "",
    subTitle: ""
  };

  algoliaConfig: any = {
    ...environment.algolia,
    indexName: "events_start_asc",
    searchParameters: {},
    routing: true
  };

  // algoliaConfig: any = {
  //   ...environment.algolia,
  //   indexName: "events_start_asc",
  //   searchParameters: {
  //     disjunctiveFacetsRefinements: {
  //       category: this.musicOnly ? ["music", "stu"] : []
  //     },
  //     disjunctiveFacets: ["category"]
  //   },
  //   routing: true
  // };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private afs: AngularFirestore
  ) {}

  ngOnInit() {
    let routeParams: any = [];

    this.route.params.subscribe(params => {
      routeParams = params;

      //Category Search
      if (routeParams.category) {
        this.algoliaConfig.searchParameters = {
          disjunctiveFacetsRefinements: {
            category: [routeParams.category]
          },
          disjunctiveFacets: ["category"]
        };

        this.searchInfo.category = routeParams.category; //.concat(" events");
      }

      //User ID search
      if (routeParams.organiserid) {
        this.algoliaConfig.searchParameters = {
          disjunctiveFacetsRefinements: {
            userRefId: [routeParams.organiserid]
          },
          disjunctiveFacets: ["userRefId"]
        };

        this.afs
          .doc(`users/${routeParams.organiserid}`)
          .valueChanges()
          .subscribe(doc => {
            console.log("doc:", doc);
            let user: any = doc;
            if (user.premium) {
              this.specialHeaderInfo.useSpecial = true;
              this.specialHeaderInfo.title = user.displayName;
              this.specialHeaderInfo.subTitle = user.preferences.about;
              //this.specialHeaderInfo.subTitle = user.displaySettings.about;
              // this.specialHeaderInfo.backgroundColor = user.preferences.backgroundColor;
              // this.specialHeaderInfo.backgroundImage = user.preferences.backgroundImage;
              console.log("this.specialHeaderInfo:", JSON.stringify(this.specialHeaderInfo));

              if (user.preferences.backgroundImage) {
                this.headerStyle = {
                  "background-image": "url(" + user.preferences.backgroundImage + ")"
                };
              } else if (user.preferences.backgroundColor) {
                this.headerStyle = {
                  "background-color": user.preferences.backgroundColor
                };
              }
            }

            //console.log("doc:", doc.data());
          });
      }

      //TO DO: geo search
    });

    this.route.queryParams.pipe(
      filter(params => params.query))
      .subscribe(params => (this.autoFocus = true));
  }

  searchChanged(query) {
    this.topResults = query.length;
  }
}
