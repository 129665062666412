import { Component, OnInit } from "@angular/core";
import { environment } from "./../../../environments/environment";
// import { InstantSearchConfig } from "angular-instantsearch";

// declare var algoliasearch: any;
// dirty way to get require to work, only for example purposes
declare var require: any;

// import the algoria library and put in your algolia ids and keys
//const algoliasearch = require("algoliasearch");
//import * as algoliasearch from "algoliasearch/lite";

//declare var autocomplete: any;
//const autocomplete = require("autocomplete.js");
//import * as algoliasearch from "algoliasearch";

import instantsearch from "instantsearch.js";
import { SearchComponent } from "../search/search.component";
// import {
//   connectSearchBox,
//   connectAutocomplete
// } from "instantsearch.js/es/connectors";

// const client = algoliasearch(
//   environment.algolia.appId,
//   environment.algolia.apiKey
// );

@Component({
  selector: "app-test",
  templateUrl: "./test.component.html",
  styleUrls: ["./test.component.scss"]
})
export class TestComponent implements OnInit {
  //https://www.algolia.com/doc/api-client/methods/search/#search-parameters
  topResults: boolean = false;
  musicOnly: boolean = false;
  //https://github.com/algolia/angular-instantsearch/issues/108
  algoliaConfig: any = {
    ...environment.algolia,
    indexName: "events_start_asc",
    searchParameters: {
      disjunctiveFacetsRefinements: {
        category: this.musicOnly ? ["music", "stu"] : []
      },
      disjunctiveFacets: ["category"]
    },
    //urlSync: true,
    routing: true
    // searchClient: algoliasearch(
    //   "VH747KOJA6",
    //   "645c850d67f5f2eb8ca35889b5e4baa9"
    // ),
    //searchFunction: function(h) {
    // if (search.helper.hasRefinements('cat_color')) {
    //   search.helper.setQueryParameter('distinct', true);
    // } else {
    //   search.helper.setQueryParameter('distinct', false);
    // }

    //h.setQueryParameter("hitsPerPage", 1).search();

    //h.addFacetRefinement("category", "music").search();

    //h.search();
    //}
    // searchParameters: {
    //   filters: "freeOptions==true"
    // }
    //searchFunction: searchFunction
  };

  // searchFunction(helper) {
  //   helper.addNumericRefinement('is_simple', '=', '1');
  //   helper.search();
  // }

  algoliaConfigWorked: any = {
    ...environment.algolia,
    indexName: "events_start_asc"
    // searchParameters: {
    //   hitsPerPage: 1
    // }
    //urlSync: true
    //routing: true
  };

  showResults = true; //false;

  constructor() {
    //https://www.youtube.com/watch?v=3Z0V3cvgns8
    //https://www.algolia.com/doc/guides/building-search-ui/resources/ui-and-ux-patterns/in-depth/autocomplete/tutorials/how-to-display-results-from-multiple-indices-with-autocomplete-js/
    //https://community.algolia.com/angular-instantsearch/widgets/configure.html
    //category
    //tags
    //venue.city
    //restrictions.ageEvent
    //starts.date
    //freeOptions
  }

  searchChanged(query) {
    this.topResults = query.length;
    //this.showResults = query.length;
  }

  ngOnInit() {
    //this.configureSearch();
    // const search = instantsearch({
    //   ...environment.algolia,
    //   indexName: 'events_start_asc',
    //   urlSync: true,
    //   searchFunction: function(h) {
    //     // if (search.helper.hasRefinements('cat_color')) {
    //     //   search.helper.setQueryParameter('distinct', true);
    //     // } else {
    //     //   search.helper.setQueryParameter('distinct', false);
    //     // }
    //     h.search();
    //   },
    // });
  }

  // configureSearch() {
  //   //AngularFire

  //   //https://www.algolia.com/doc/api-reference/widgets/autocomplete/js/

  //   //MULTIPLE INDICES
  //   //https://www.algolia.com/doc/guides/building-search-ui/resources/ui-and-ux-patterns/in-depth/autocomplete/tutorials/how-to-display-results-from-multiple-indices-with-autocomplete-js/

  //   const renderIndexListItem = ({ label, hits }) => `
  //     <li>
  //       Index: ${label}
  //       <ol>
  //         ${hits.map(hit => `<li>${hit.name}</li>`).join("")}
  //       </ol>
  //     </li>
  //   `;

  //   const search = instantsearch({
  //     indexName: "events",
  //     searchClient: client,
  //     // Optional parameters
  //     routing: true
  //   });
  //   const renderAutocomplete = (renderOptions, isFirstRender) => {
  //     const {
  //       indices,
  //       currentRefinement,
  //       refine,
  //       widgetParams
  //     } = renderOptions;

  //     if (isFirstRender) {
  //       console.log("first render");
  //       const input = document.createElement("input");
  //       const ul = document.createElement("ul");

  //       input.addEventListener("input", event => {
  //         refine((<HTMLInputElement>event.target).value);
  //         //refine(event.currentTarget.value);
  //       });

  //       widgetParams.container.appendChild(input);
  //       widgetParams.container.appendChild(ul);
  //     }

  //     widgetParams.container.querySelector("input").value = currentRefinement;
  //     widgetParams.container.querySelector("ul").innerHTML = indices
  //       .map(renderIndexListItem)
  //       .join("");
  //   };

  //   const customAutocomplete = connectAutocomplete(renderAutocomplete);

  //   search.addWidget(
  //     customAutocomplete({
  //       container: document.querySelector("#autocomplete"),
  //       indices: [
  //         { label: "Events", value: "events" }
  //         //   { label: "Price (desc)", value: "instant_search_price_desc" }
  //       ]
  //     })
  //   );

  //   search.start();
  //   // search.addWidget(
  //   //   customAutocomplete({
  //   //     // instance params
  //   //   });
  //   // );
  // }
  // //   configureSearchOriginal() {
  // //     //https://github.com/algolia/algoliasearch-client-javascript
  // //     //https://github.com/algolia/autocomplete.js

  // //     var client = algoliasearch(
  // //       environment.algolia.appId,
  // //       environment.algolia.apiKey
  // //     );

  // //     var index = client.initIndex("events");
  // //     autocomplete("#search-input", { hint: false }, [
  // //       {
  // //         source: autocomplete.sources.hits(index, { hitsPerPage: 8 }),
  // //         displayKey: "name",
  // //         templates: {
  // //           suggestion: function(suggestion) {
  // //             //console.log("suggestion:", JSON.stringify(suggestion));
  // //             return suggestion._highlightResult.name.value;
  // //           }
  // //         }
  // //       }
  // //     ]).on("autocomplete:selected", function(
  // //       event,
  // //       suggestion,
  // //       dataset,
  // //       context
  // //     ) {
  // //       console.log(event);
  // //       console.log(suggestion);
  // //       console.log(dataset);
  // //       console.log(context);
  // //     });
  // //   }
}
