import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument
} from "angularfire2/firestore";
import { ToasterModule, ToasterService } from "angular5-toaster";
import { FormControl } from "@angular/forms";

import { Event } from "../../../../types/event";

@Component({
  selector: "app-name-badges",
  templateUrl: "./name-badges.component.html",
  styleUrls: ["./name-badges.component.scss"]
})
export class NameBadgesComponent implements OnInit {
  eventId: string;
  event: Event = new Event();

  constructor(
    private afs: AngularFirestore,
    private route: ActivatedRoute,
    private toasterService: ToasterService
  ) {}

  ngOnInit() {
    this.setupPage();
  }

  setupPage() {
    this.route.params.subscribe(params => {
      if (params.eventid) {
        this.eventId = params.eventid;
        this.afs
          .collection("events")
          .doc(this.eventId)
          .valueChanges()
          .subscribe(doc => (this.event = <Event>doc));
      }
    });

    this.formValidation();
  }

  formValidation() {
    var that = this;
    var forms = document.getElementsByClassName("needs-validation");
    var validation = Array.prototype.filter.call(forms, function(form) {
      form.addEventListener(
        "submit",
        function(event) {
          if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            //toast
            that.displayNotification(
              "Oops!",
              "Make sure you have filled all of the fields correctly.",
              true
            );
          }
          form.classList.add("was-validated");
        },
        false
      );
    });
  }

  displayNotification(title: string, msg: string, error: boolean = false) {
    this.toasterService.pop({
      type: error ? "error" : "success",
      title: title,
      body: msg,
      timeout: 2500
    });
  }
}
