import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./core/auth/auth.guard";

import { HomeComponent } from "./components/home/home.component";
import { SignupComponent } from "./components/signup/signup.component";
import { LoginComponent } from "./components/login/login.component";
import { AccountComponent } from "./components/account/account.component";
import { MyComponent } from "./components/account/my/my.component";
import { DetailsComponent } from "./components/account/details/details.component";

import { EventsComponent } from "./components/account/events/events.component";
import { EventComponent } from "./components/account/events/event/event.component";
import { EventDashComponent } from "./components/account/events/event-dash/event-dash.component";
import { TicketsComponent } from "./components/account/tickets/tickets.component";
import { PaymentComponent } from "./components/account/events/payment/payment.component";

import { NotFoundComponent } from "./components/not-found/not-found.component";

import { StandardContentComponent } from "./components/content/standard-content/standard-content.component";
import { FaqsComponent } from "./components/content/faqs/faqs.component";
import { CmsComponent } from "./components/content/cms/cms.component";

import { DemoComponent } from "./components/demo/demo.component";
import { TestComponent } from "./components/test/test.component";
import { SearchComponent } from "./components/search/search.component";
import { LiveStreamComponent } from "./components/live-stream/live-stream.component";
import { DetailComponent } from "./components/detail/detail.component";
import { BookComponent } from "./components/book/book.component";
import { ConfirmComponent } from "./components/confirm/confirm.component";

import { DesignComponent } from "./components/account/events/design/design.component";
import { AccomSetupComponent } from "./components/account/events/accom-setup/accom-setup.component";
import { SocialSetupComponent } from "./components/account/events/social-setup/social-setup.component";
import { DiscountsSetupComponent } from "./components/account/events/discounts-setup/discounts-setup.component";
import { InvitesSetupComponent } from "./components/account/events/invites-setup/invites-setup.component";
import { AttendeeReportComponent } from "./components/account/events/attendee-report/attendee-report.component";
import { NameBadgesComponent } from "./components/account/events/name-badges/name-badges.component";
import { EmailAttendeesComponent } from "./components/account/events/email-attendees/email-attendees.component";
import { CheckinReportComponent } from "./components/account/events/checkin-report/checkin-report.component";
import { TicketTypesComponent } from "./components/account/events/tickets/tickets.component";
import { TourComponent } from "./components/account/events/tour/tour.component";

import { ResultsComponent } from "./components/accommodation/search/results/results.component";

const routes: Routes = [
  { path: "", redirectTo: "/home", pathMatch: "full" },
  { path: "home", component: HomeComponent },
  {
    path: "test",
    component: TestComponent,
    data: { theme: "light", hideFooter: false }
  },
  {
    path: "demo",
    component: DemoComponent,
    data: { theme: "light", hideFooter: false }
  },
  {
    path: "standard",
    component: StandardContentComponent,
    data: { theme: "light", hideFooter: false }
  },
  { path: "signup", component: SignupComponent, data: { theme: "light" } },
  { path: "login", component: LoginComponent, data: { theme: "light" } },
  { path: "tour", component: TourComponent, data: { theme: "light" } },
  {
    path: "account",
    component: AccountComponent,
    data: { theme: "darkAccount" },
    canActivate: [AuthGuard]
  },
  {
    path: "account/my",
    component: MyComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "details",
        component: DetailsComponent,
        data: { theme: "light" }
      },
      { path: "events", component: EventsComponent, data: { theme: "light" } },
      {
        path: "events/:eventid",
        component: EventComponent,
        data: { theme: "light" }
      },
      {
        path: "events/:eventid/dash",
        component: EventDashComponent,
        data: { theme: "light" }
      },
      {
        path: "events/:eventid/tickets",
        component: TicketTypesComponent,
        data: { theme: "light" }
      },
      {
        path: "events/:eventid/design",
        component: DesignComponent,
        data: { theme: "light" }
      },
      {
        path: "events/:eventid/payment",
        component: PaymentComponent,
        data: { theme: "light" }
      },
      {
        path: "events/:eventid/accom-setup",
        component: AccomSetupComponent,
        data: { theme: "light" }
      },
      {
        path: "events/:eventid/social-setup",
        component: SocialSetupComponent,
        data: { theme: "light" }
      },
      {
        path: "events/:eventid/discounts-setup",
        component: DiscountsSetupComponent,
        data: { theme: "light" }
      },
      {
        path: "events/:eventid/invites-setup",
        component: InvitesSetupComponent,
        data: { theme: "light" }
      },
      {
        path: "events/:eventid/attendee-report",
        component: AttendeeReportComponent,
        data: { theme: "light" }
      },
      {
        path: "events/:eventid/checkin-report",
        component: CheckinReportComponent,
        data: { theme: "light" }
      },
      {
        path: "events/:eventid/name-badges",
        component: NameBadgesComponent,
        data: { theme: "light" }
      },
      {
        path: "events/:eventid/email-attendees",
        component: EmailAttendeesComponent,
        data: { theme: "light" }
      },
      {
        path: "tickets",
        component: TicketsComponent,
        data: { theme: "light" }
      },
      {
        path: "tickets/:ticketid",
        component: TicketsComponent,
        data: { theme: "light" }
      }
    ]
  },
  { path: "events", component: SearchComponent, data: { theme: "light" } },
  { path: "search", component: SearchComponent, data: { theme: "light" } },
  { path: "search/:location", component: SearchComponent },
  { path: "search/:location/:category", component: SearchComponent },
  { path: "venue/:venue", component: SearchComponent },
  { path: "events/organisers/:organiserid", component: SearchComponent },
  { path: "event/live-stream/:slug", component: LiveStreamComponent },
  { path: "event/:slug", component: DetailComponent },
  //{ path: "event/:eventid", component: DetailComponent },
  {
    path: "event/:slug/book",
    component: BookComponent,
    data: { theme: "light" }
  },
  {
    path: "event/:slug/confirm",
    component: ConfirmComponent,
    data: { theme: "light" }
  },
  {
    path: "event/:slug/accommodation",
    component: ResultsComponent,
    data: { theme: "light" }
  },
  {
    path: "404",
    component: NotFoundComponent,
    data: { theme: "light", hideFooter: false }
  },
  { path: "faqs", component: FaqsComponent },
  //Route anything via CMS first - see how this goes and if any DDoS we'll try another way...
  {
    path: "**",
    component: CmsComponent,
    data: { theme: "dark" }
  }
  //404-not found
  // { path: "**", redirectTo: "/404" }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuard]
})
export class AppRoutingModule {}
