import { Injectable } from "@angular/core";
import * as moment from "moment";

@Injectable()
export class DateService {
  constructor() {}

  isPast(event): boolean {
    let today = new Date();
    today.setDate(today.getDate() + 1); // tomorrow

    //prettier-ignore
    if (event.ends) return moment(event.ends.epoc * 1000).isBefore(today, "day");
    //prettier-ignore
    return moment(event.starts.epoc * 1000).isBefore(today,"day");
  }

  isMultipleDatesAvailable(obj): boolean {
    let multiDays: boolean = false;
    if (!obj.multiDaySingleEvent) {
      if (obj.ends) multiDays = true;
    }
    return multiDays;
  }

  isCurrent(obj): boolean {
    let today = new Date();
    today.setDate(today.getDate() + 1); // tomorrow

    //prettier-ignore
    return moment(obj.ends.epoc * 1000).isBefore(today, "day") && moment(obj.starts.epoc * 1000).isAfter(today, "day");
  }

  daysApart(obj): number {
    return moment(obj.ends.formatted).diff(obj.starts.formatted, "day") + 1;
  }

  friendlyEventDates(obj): string {
    let str: string = obj.starts.formatted;
    if (!obj.multiDaySingleEvent) {
      if (obj.ends) str = `${str} - ${obj.ends.formatted}`;
    } else {
      let days: number = this.daysApart(obj);
      str = `${obj.starts.formatted} over ${days} ${
        days == 1 ? "day" : "days"
      }`;
    }
    return str;
  }
}
