import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { MapsAPILoader } from "@agm/core";

import * as Typed from "typed.js";

declare var google: any;

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"]
})
export class HomeComponent implements OnInit {
  userDefaultCity: string;
  searchString: string;

  constructor(private mapsAPILoader: MapsAPILoader, private router: Router) {}

  ngOnInit() {
    this.setTypedText();
    this.setLocation();
  }

  search() {
    //this.router.navigate(['/search']);
    this.router.navigate(["/search"], {
      queryParams: { query: this.searchString }
    });
  }

  setLocation(): void {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        this.mapsAPILoader.load().then(() => {
          var geocoder = new google.maps.Geocoder();
          var self = this;
          geocoder.geocode(
            {
              latLng: new google.maps.LatLng(
                position.coords.latitude,
                position.coords.longitude
              )
            },
            function(results, status) {
              if (status == google.maps.GeocoderStatus.OK) {
                if (results[1]) {
                  for (var i = 0; i < results.length; i++) {
                    if (results[i].types[0] === "locality") {
                      self.userDefaultCity =
                        results[i].address_components[0].short_name +
                        ", " +
                        results[i].address_components[2].long_name;
                    }
                  }
                }
              }
            }
          );
        });
      });
    }
  }

  setTypedText(): void {
    let typed = new Typed(".typewrite", {
      strings: [
        "Events in Brisbane",
        "Sporting Events",
        "Events in Byron Bay",
        "#kimsbridalshower"
      ],
      typeSpeed: 100,
      backSpeed: 50,
      showCursor: true,
      cursorChar: "|",
      loop: true
    });
  }
}
