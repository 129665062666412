export interface Venue {
  googlePlaceId?: string;
  name: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  postcode: string;
  country: string;

  reviews?: any;

  latitude: number;
  longitude: number;

  gettingThere?: string;
  trainDistance?: string;
  planeDistance?: string;
  busDistance?: string;
}

export class Venue {
  googlePlaceId?: string;
  name: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  postcode: string;
  country: string;

  reviews?: any = [];

  latitude: number;
  longitude: number;

  gettingThere?: string;
  trainDistance?: string;
  planeDistance?: string;
  busDistance?: string;
}
