
import {map} from 'rxjs/operators';
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import {
  AngularFirestore,
  AngularFirestoreCollection
} from "angularfire2/firestore";

import { Event } from "../../types/event";
import { Tickets } from "../../types/tickets";

import { DateService } from "../../core/date.service";

declare var $: any;
import * as _ from "lodash";
import * as moment from "moment";
import * as Color from "color";

@Component({
  selector: "app-detail",
  templateUrl: "./detail.component.html",
  styleUrls: ["./detail.component.scss"]
})
export class DetailComponent implements OnInit {
  loading: boolean = true;
  notFound: boolean = false;
  slug: string;
  private sub: any;

  eventDetails: Event;
  organiser: any;
  tickets: any[];

  icon = {
    url: "/assets/images/maps-and-flags.svg",
    scaledSize: {
      width: 38,
      height: 38
    }
  };

  constructor(
    private route: ActivatedRoute,
    private dateService: DateService,
    private afs: AngularFirestore
  ) {}

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.slug = params["slug"];
      this.getEvent();
    });
  }

  getEvent() {
    this.afs
      .collection<Event>("events", ref => ref.where("slug", "==", this.slug))
      .snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as any;
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      }))
      .subscribe(data => {
        if (data.length > 0) {
          this.eventDetails = data[0];

          this.afs
            .collection(`/events/${this.eventDetails.id}/tickets`, ref =>
              ref.where("active", "==", true).orderBy("order", "asc")
            )
            .valueChanges()
            .subscribe(ticketdata => {
              let that = this;
              //prettier-ignore
              _.remove(ticketdata, function(ticket) {
                return (ticket.scheduled && that.dateService.isCurrent(ticket));
              });

              if (ticketdata) this.tickets = ticketdata;
            });

          this.eventDetails.userRef.get().then(userDoc => {
            this.organiser = userDoc.data();
            this.organiser.link = "/events/organisers/" + this.organiser.uid;
          });
        } else {
          //404
          this.notFound = true;
        }
        this.loading = false;
      });
  }

  terms() {
    $("html, body").animate(
      {
        scrollTop: $("#terms").offset().top
      },
      1500
    );
  }

  ticketsInfo() {
    $("html, body").animate(
      {
        scrollTop: $("#tickets").offset().top
      },
      1500
    );
  }

  // organiserInfo() {
  //   $("html, body").animate(
  //     {
  //       scrollTop: $("#organiser").offset().top
  //     },
  //     1500
  //   );
  // }

  map() {
    $("html, body").animate(
      {
        scrollTop: $("#gettingthere").offset().top
      },
      1500
    );
  }

  set() {
    // this.eventDetails.largePhotos = [
    //   "https://s3-ap-southeast-2.amazonaws.com/fibie-content/DSC_3766.jpg",
    //   "https://s3-ap-southeast-2.amazonaws.com/fibie-content/RAHLaterJools021.jpg"
    // ];
    // this.eventDetails.design = {
    //   headerAlign: "center",
    //   headerFont: "Arial",
    //   primaryColor: "#8345A2",
    //   highlightColor: "#CE6DFF",
    //   lightColor: "#E8B9FF",
    //   darkColor: "#745D7F"
    // };
    //this.eventDetails.name = "Another Band Test";
    //this.afs.collection("events").add(this.eventDetails);
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
