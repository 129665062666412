import { Component, OnInit, Input } from "@angular/core";
import { Observable } from "rxjs";
import {
  AngularFirestore,
  AngularFirestoreCollection
} from "angularfire2/firestore";
import { AuthService } from "../../../core/auth/auth.service";

import { Router } from "@angular/router";

import { Event } from "../../../types/event";
import { StatusService } from "../../../core/status.service";
import { DateService } from "../../../core/date.service";

import * as _ from "lodash";
import * as moment from "moment";

@Component({
  selector: "app-events",
  templateUrl: "./events.component.html",
  styleUrls: ["./events.component.scss"]
})
export class EventsComponent implements OnInit {
  noEvents: boolean = true;

  eventsCollection: AngularFirestoreCollection<Event>;
  events: Observable<Event[]>;

  currentEvents: Event[];
  pastEvents: Event[];

  constructor(
    private afs: AngularFirestore,
    private router: Router,
    public auth: AuthService,
    private dateService: DateService
  ) {}

  ngOnInit() {
    this.auth.user.subscribe(user => {
      var userRef = this.afs.doc(`users/${user.uid}`).ref;

      //prettier-ignore
      this.eventsCollection = this.afs.collection<Event>("events", ref => ref.where("userRef", "==", userRef));
      this.events = this.eventsCollection.valueChanges();
      this.events.subscribe(data => {
        if (data.length > 0) {
          this.noEvents = false;

          data = _.sortBy(data, function(dateObj) {
            return new Date(dateObj.starts.epoc);
          });

          this.currentEvents = _.clone(data, true);
          this.pastEvents = _.clone(data, true);

          let that = this;

          //prettier-ignore
          _.remove(this.currentEvents, function(currentObject) { return that.dateService.isPast(currentObject) });
          _.remove(this.pastEvents, function(currentObject) { return !that.dateService.isPast(currentObject) });
          //prettier-ignore
        } else {
          this.noEvents = true;
          this.router.navigate(["/account/my/events/first"]);
        }
      });
    });
  }

  manageEvent(id) {
    this.router.navigate(["/account/my/events/" + id + "/dash"]);
  }
}

@Component({
  selector: "app-event-card",
  template: `
    <div *ngIf="_eventInfo" class="card h-100">
      <div class="card-body">
        <h5 class="card-title">{{ _eventInfo.name }}</h5>
        <h6 class="card-subtitle mb-2 text-muted">
          <i class="fa fa-map-marker"></i> {{ _eventInfo.venue.name }}
        </h6>
        <p class="card-text">
          <i class="fa fa-calendar"></i> {{ _eventInfo.starts.formatted }}
          <span *ngIf="_eventInfo.ends">
            - {{ _eventInfo.ends.formatted }}</span
          >
        </p>
      </div>
      <div class="card-links">
        <span [class]="this._status?.badgeStyle">{{
          this._status?.badgeText
        }}</span>
      </div>
    </div>
  `,
  styleUrls: ["./events.component.scss"]
})
export class EventCardComponent {
  _status: any;
  _eventInfo: Event;

  @Input()
  set event(eventInfo: Event) {
    this._eventInfo = eventInfo;
    this._status = this.statusService.getStatus(eventInfo);
  }

  constructor(private statusService: StatusService) {}
}
