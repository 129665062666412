import {
  Component,
  OnChanges,
  Input,
  Output,
  EventEmitter
} from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { environment } from "../../../environments/environment";

import { Event } from "../../types/event";

import * as _ from "lodash";
import * as moment from "moment";

@Component({
  selector: "app-accom-card",
  templateUrl: "./accomcard.component.html",
  styleUrls: ["./accomcard.component.scss"]
})
export class AccomCardComponent implements OnChanges {
  @Input() getEverything: boolean = false;
  @Input() dates: any = null;
  @Input() property: any;
  @Input() event: Event;

  @Output() selected: EventEmitter<any> = new EventEmitter<any>();

  reviewInfo: any = null;

  constructor(
    //private changeDetectorRef: ChangeDetectorRef,
    private http: HttpClient
  ) {}

  ngOnChanges() {
    this.getPropertyPricesAndReviews();
    //this.changeDetectorRef.detectChanges();
  }

  setProperty() {
    this.selected.emit(this.property);
  }

  async getPropertyPricesAndReviews() {
    if (this.property.id == "52") {
      console.log(
        JSON.stringify({
          dateFrom: this.dates
            ? moment(this.dates.start).format("YYYY-MM-DD")
            : moment(this.event.starts.formatted).format("YYYY-MM-DD"),
          dateTo: this.dates
            ? moment(this.dates.end).format("YYYY-MM-DD")
            : moment(this.event.ends.formatted)
                .add(1, "days")
                .format("YYYY-MM-DD"),
          numberOfAdults: 2,
          numberOfChildren: 0,
          hotelId: this.property.id,
          currency: "AUD",
          returnAllRates: true
        })
      );
    }
    this.http
      .post(`${environment.obe.uri}Hotel/GetRoomByHotelId`, {
        dateFrom: this.dates
          ? moment(this.dates.start).format("YYYY-MM-DD")
          : moment(this.event.starts.formatted).format("YYYY-MM-DD"),
        dateTo: this.dates
          ? moment(this.dates.end).format("YYYY-MM-DD")
          : moment(this.event.ends.formatted)
              .add(1, "days")
              .format("YYYY-MM-DD"),
        numberOfAdults: 2,
        numberOfChildren: 0,
        hotelId: this.property.id,
        currency: "AUD",
        returnAllRates: true
      })
      .subscribe(data => {
        let rates: any = data;

        if (this.property.id == "52") {
          console.log("rates:", data);
        }
        if (rates.responseCode == 200) {
          this.property.fromRate = rates.lowestDailyRate;
          if (this.getEverything) this.property.rates = rates.rooms;
        } else {
          this.property.rates = null;
          this.property.fromRate = 0;
        }
      });
    //got the regex from OBE to match
    if (this.reviewInfo) {
      this.bindReviewInfo();
    } else {
      //`${environment.obe.places.uri}hotels?name=${this.property.name
      // `${environment.obe.places.uri}api/places/${this.property.name
      this.http
        .get(
          `${environment.obe.places.uri}hotels?name=${this.property.name
            .replace(new RegExp("&", "gi"), "")
            .replace(new RegExp("@", "gi"), "")
            .replace(new RegExp("  ", "gi"), "+")
            .replace(new RegExp(" ", "gi"), "+")}`
        )
        .subscribe(data => {
          this.reviewInfo = data;
          this.bindReviewInfo();
        });
    }
  }

  bindReviewInfo() {
    //console.log(JSON.stringify(this.reviewInfo));
    if (this.reviewInfo.reviews && this.reviewInfo.reviews.length) {
      let maxReview: any = _.minBy(this.reviewInfo.reviews, o => o.text.length); //get shortest review
      let reviewSummary: any = {
        rating: this.reviewInfo.rating,
        ratingDescription: this.getRatingDescription(this.reviewInfo.rating),
        ratingsTotal: this.reviewInfo.ratingsTotal,
        initials: maxReview.authorName.match(/\b(\w)/g).join(""),
        comment: maxReview.text
      };

      this.property.reviewSummary = reviewSummary;
      if (this.getEverything) this.property.reviews = this.reviewInfo.reviews;
    } else {
      this.property.reviewSummary = {
        rating: "N/A",
        ratingsTotal: 0
      };
    }
  }

  getRatingDescription(rating) {
    switch (Math.round(rating)) {
      case 5:
        return "Excellent";
      case 4:
        return "Very Good";
      case 3:
        return "Good";
      case 2:
        return "Not Great";
      case 1:
        return "Not Good";
      default:
        "N/A";
    }
  }
}
