import { Venue } from "./venue";
import { PaymentSetup } from "./paymentSetup";

export interface Event {
  id?: string;
  //userid: string;
  name: string;
  slug: string;
  smallPhoto: string;
  largePhoto: string;
  largePhotos?: any;
  logo?: string;
  venue?: Venue;
  starts?: any;
  startsTime?: any;
  ends?: any;
  endsTime?: any;
  approved: boolean;
  private: boolean;

  multiDaySingleEvent: boolean;
  ageRestrictions: boolean;
  minAge: number;
  reservedSeating: boolean;
  onlineOnly: boolean;
  purchaseLimits: boolean;
  refundsAllowed: boolean;
  freeOptions?: boolean;

  fromPrice?: number;
  toPrice?: number;

  maxAttendance?: boolean;
  maxAttendanceNumber?: number;

  scanCodeType: string;

  customFields: any;
  currencyCode: string;
  captureAddress: boolean;
  category: string;

  individualNamesOnTickets: boolean;
  descriptionGeneral: string;
  descriptionShort: string;
  descriptionCarparking: string;
  descriptionTermsConditions: string;
  tags?: any;
  design?: any;
  earlyBird?: boolean;

  showAccommodation?: boolean;
  //accommodationPriority?: any;

  userRef: any;

  //paymentSetup?: PaymentSetup;
  paymentSetup?: any;
}

export class Event {
  id?: string;
  //userid: string;
  name: string;
  slug: string;
  smallPhoto: string;
  largePhoto: string;
  largePhotos?: any = [];
  logo?: string;
  venue?: Venue = new Venue();
  starts?: any;
  startsTime?: any = "19:00";
  ends?: any;
  endsTime?: any = "21:00";
  approved: boolean = false;
  private: boolean = false;

  multiDaySingleEvent: boolean = true;
  ageRestrictions: boolean = false;
  minAge: number = 18;
  reservedSeating: boolean = false;
  onlineOnly: boolean = false;
  purchaseLimits: boolean = false;
  refundsAllowed: boolean = true;
  freeOptions?: boolean = false;

  fromPrice?: number = 0;
  toPrice?: number = 0;

  maxAttendance?: boolean = false;
  maxAttendanceNumber?: number;

  scanCodeType: string = "qrcode";

  customFields: any = [];
  currencyCode: string = "AUD";
  captureAddress: boolean = false;

  category: string = "";
  individualNamesOnTickets: boolean = false;

  descriptionGeneral: string;
  descriptionShort: string = "";
  descriptionTermsConditions: string;
  tags?: any = [];
  design?: any = {
    callToAction: "Register Now",
    backgroundColor: "#745D7F",
    animation: "flipInX"
  };
  earlyBird?: boolean = false;

  showAccommodation?: boolean = false;
  //accommodationPriority?: any = []; //move to a separate collection

  userRef: any;

  paymentSetup?: any;
  //paymentSetup?: PaymentSetup = new PaymentSetup();
}
