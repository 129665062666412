import { Component, OnInit } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AuthService } from "../../../../core/auth/auth.service";

import { environment } from "../../../../../environments/environment";
import { DirectusService } from "../../../../core/directus/directus.service";

import {
  ActivatedRoute,
  ParamMap,
  Params,
  Router,
  UrlSegment
} from "@angular/router";
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument
} from "angularfire2/firestore";
import { ToasterModule, ToasterService } from "angular5-toaster";

import { FormControl } from "@angular/forms";

import { Event } from "../../../../types/event";
import { Venue } from "../../../../types/venue";

declare var $: any;
import * as _ from "lodash";

@Component({
  selector: "app-design",
  templateUrl: "./design.component.html",
  styleUrls: ["./design.component.scss"]
})
export class DesignComponent implements OnInit {
  eventId: string;
  event: Event = new Event();

  loading: boolean = false;
  galleryLoading: boolean = false;
  galleryPage: number = 1;
  galleryTag: string = "event";
  galleryPages: number = 0;
  stockGallery: any = null;
  callToActions: any = [];

  thumbnailImage: any = {
    url: "",
    s3name: ""
  };

  headerImage: any = {
    url: "",
    s3name: ""
  };

  constructor(
    public auth: AuthService,
    private toasterService: ToasterService,
    private afs: AngularFirestore,
    private route: ActivatedRoute,
    private http: HttpClient,
    private directusService: DirectusService
  ) {}

  ngOnInit() {
    this.setupPage();
  }

  setupPage() {
    this.route.params.subscribe(params => {
      if (params.eventid) {
        this.eventId = params.eventid;
        this.afs
          .collection("events")
          .doc(this.eventId)
          .valueChanges()
          .subscribe(doc => {
            this.event = <Event>doc;

            this.headerImage.url = this.event.largePhoto;
            this.headerImage.s3name = "events/images/" + this.eventId;

            this.thumbnailImage.url = this.event.smallPhoto;
            this.thumbnailImage.s3name = "events/images/thumbs/" + this.eventId;
          });
      }
    });
    this.directusService
      .getCollection("calltoactions")
      .then(res => (this.callToActions = res));
  }

  onLargePhoto(url) {
    this.event.largePhoto = url;
    this.headerImage.url = this.event.largePhoto;
  }

  onLogo(url) {
    this.event.logo = url;
  }

  onSmallPhoto(url) {
    this.event.smallPhoto = url;
    this.thumbnailImage.url = this.event.smallPhoto;
  }

  animateText(evt) {
    $(".title").animate();
  }

  onLoadGallery() {
    this.galleryLoading = true;

    this.http
      .get(
        `${environment.unsplash.uri}search/photos?orientation=landscape&query=${this.galleryTag}&page=${this.galleryPage}&client_id=${environment.unsplash.clientId}`
      )
      .subscribe(data => {
        this.galleryPages = data["total_pages"] > 10 ? 10 : data["total_pages"];
        this.galleryLoading = false;
        this.stockGallery = _.take(data["results"], 9);
      });
  }

  onStockPhoto(urls) {
    this.event.largePhoto = urls.regular;
    this.headerImage.url = this.event.largePhoto;

    this.event.smallPhoto = urls.small;
    this.thumbnailImage.url = this.event.smallPhoto;

    $("#myModal").modal("hide");
  }

  save() {
    this.loading = true;

    this.afs
      .collection("events")
      .doc(this.event.id)
      .update(this.event)
      .then(ref => {
        this.loading = false;
        this.toasterService.pop({
          type: "success",
          title: "Changes saved!",
          body: "You settings are now live",
          timeout: 3500
        });
      });
  }
}
