import { Component, OnInit } from "@angular/core";
import { AuthService } from "../../core/auth/auth.service";
import { Observable ,  BehaviorSubject } from "rxjs";
import {
  FormGroup,
  AbstractControl,
  FormBuilder,
  Validators
} from "@angular/forms";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"]
})
export class LoginComponent implements OnInit {
  //   form: FormGroup;
  //   email: AbstractControl;
  //   name: AbstractControl;
  //   password: AbstractControl;
  //   password2: AbstractControl;

  //   @Output() onSuccess = new EventEmitter();
  //   @Output() onError = new EventEmitter();

  constructor(public auth: AuthService) {
    //     private fb: FormBuilder) {
    // this.form = fb.group({
    // 'name': ['', Validators.required],
    // 'email': ['', Validators.compose([
    //     Validators.required,
    //     Validators.email]
    // )],
    // 'password': ['', Validators.required],
    // 'password2': ['', Validators.required]
    // }, {validator: this.matchingPasswords('password', 'password2')});
    // this.name = this.form.controls['name'];
    // this.email = this.form.controls['email'];
    // this.password = this.form.controls['password'];
    // this.password2 = this.form.controls['password2'];
  }

  ngOnInit() {}

  // onSubmit() {
  // if (this.form.valid) {
  // this.authService.createUser(this.email.value, this.password.value, this.name.value)
  //     .subscribe(
  //         () => {
  //             this.onSuccess.emit("success");
  //             this.form.reset();
  //         },
  //         err => this.onError.emit(err)
  //     );
  // }
  // }

  // matchingPasswords(passwordKey: string, confirmPasswordKey: string) {
  // return (group: FormGroup): {[key: string]: any} => {
  // let password = group.controls[passwordKey];
  // let confirmPassword = group.controls[confirmPasswordKey];

  // if (password.value !== confirmPassword.value) {
  //     return {
  //         mismatchedPasswords: true
  //     };
  // }
  // }
  // }
}
