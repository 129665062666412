export interface PaymentSetup {
  useDefaultBanking: boolean;
  passFeeToCustomer: boolean;
  fibieFee: number;

  accountName?: string;
  accountBSB?: string;
  accountNumber?: string;
}

export class PaymentSetup {
  useDefaultBanking: boolean = true;
  passFeeToCustomer: boolean = true;
  fibieFee: number = 0.01;

  accountName?: string = "";
  accountBSB?: string = "";
  accountNumber?: string = "";
}
