
import {filter} from 'rxjs/operators';
import { Component } from "@angular/core";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";

import "rxjs/add/operator/filter";
import "rxjs/add/operator/map";
import "rxjs/add/operator/mergeMap";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent {
  useLightTheme: boolean = false;
  useDarkAccountTheme: boolean = false;
  hideFooter: Boolean = false;

  constructor(private router: Router, private route: ActivatedRoute) {
    this.setupTemplate();
  }

  ngOnInit() {
    //Router scroll to top of the page
    this.router.events.subscribe(evt => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }

  setupTemplate() {
    this.router.events.pipe(
      filter((event: any) => event instanceof NavigationEnd))
      .subscribe(() => {
        var root = this.router.routerState.snapshot.root;
        while (root) {
          if (root.children && root.children.length) {
            root = root.children[0];
          } else if (root.data) {
            this.useLightTheme = root.data["theme"] == "light";
            this.useDarkAccountTheme = root.data["theme"] == "darkAccount";
            this.hideFooter = root.data["hideFooter"] == true;
            return;
          } else {
            return;
          }
        }
      });
  }
}
