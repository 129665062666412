import { Component, OnInit } from "@angular/core";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";

import { DirectusService } from "../../../core/directus/directus.service";

import * as AOS from "aos";

@Component({
  selector: "app-cms",
  templateUrl: "./cms.component.html",
  styleUrls: ["./cms.component.scss"]
})
export class CmsComponent implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private directusService: DirectusService
  ) {}

  pageContent: any;

  ngOnInit() {
    AOS.init();

    this.directusService.getPageByUrl(this.router.url).subscribe(data => {
      if (data) {
        this.pageContent = data;
        this.pageContent = this.pageContent.data[0];

        //console.log("this.pageContent:", JSON.stringify(this.pageContent));

        if (!this.pageContent) {
          this.router.navigate(["/404"]);
        }
      }
    });
  }
}
