import { Component, OnInit } from "@angular/core";
import { AuthService } from "../../../core/auth/auth.service";
import { Router } from "@angular/router";
import { Title } from "@angular/platform-browser";
import { ToasterService } from "angular5-toaster";
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument
} from "angularfire2/firestore";

import * as AOS from "aos";
import * as _ from "lodash";

import { UserPreferences, UserEmailPreferences } from "../../../types/user";

@Component({
  selector: "app-details",
  templateUrl: "./details.component.html",
  styleUrls: ["./details.component.scss"]
})
export class DetailsComponent implements OnInit {
  user: any = {};
  preferences: UserPreferences = new UserPreferences();
  emailPreferences: UserEmailPreferences = new UserEmailPreferences();

  loading: boolean = false;

  userProfileImage: any = {
    url: "",
    s3name: ""
  };

  constructor(
    public auth: AuthService,
    private titleService: Title,
    private toasterService: ToasterService
  ) {
    this.titleService.setTitle("My Account Details | Fibie");
  }

  ngOnInit() {
    this.auth.user.subscribe(user => {
      this.user = user;

      //if (user.preferences) this.preferences = user.preferences;
      if (!user.emailPreferences) this.user.emailPreferences = new UserEmailPreferences();

      this.userProfileImage.url = user.photoURL ? user.photoURL : "/assets/images/empty_avatar.png";
      this.userProfileImage.s3name = "profiles/" + this.user.uid;
    });
  }

  onNewAvatar(url) {
    this.user.photoURL = url;
  }

  deleteAvatar() {
    this.userProfileImage.url = "/assets/images/empty_avatar.png";
    this.user.photoURL = null;
  }

  save() {
    this.loading = true;

    this.auth
      .updateUserData(this.user)
      .then(() => {
        this.toasterService.pop({
          type: "success",
          title: "Success",
          body: "Your changes have been saved",
          timeout: 3500
        });
        this.loading = false;
      })
      .catch(err => {
        this.toasterService.pop({
          type: "error",
          title: "Error saving your details",
          body: err.message,
          timeout: 3500
        });
        this.loading = false;
      });
  }

  savePreferences() {
    //updateUserPreferences
  }
}
