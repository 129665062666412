export interface Registration {
  id?: string;
  uid: any;
  eventRef?: any;
  status: any;
  email: string;
  firstname: string;
  lastname: string;
  phone: string;
  address: RegistrationAddress;
  payment?: any;
  //payment: RegistrationPayment;
  tickets: any;
}

export class RegistrationAddress {
  address1: string;
  address2: string;
  city: string;
  country: string = "";
  state: string = "";
  postcode: string;
}

export class RegistrationPayment {
  cardname: string;
  cardnumber: string;
  cardexpiry: string;
  cardcvc: string;
}

export class Registration {
  id?: string;
  uid: any;
  eventRef?: any;
  status: any; //[COMPLETE, FAILED_PAYMENT, CANCELLED]
  email: string;
  firstname: string;
  lastname: string;
  phone: string;
  address: RegistrationAddress = new RegistrationAddress();
  payment?: any;
  //payment: RegistrationPayment = new RegistrationPayment();
  tickets: any = [];
}
