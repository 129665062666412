import { Injectable } from "@angular/core";
//import { Upload } from "../types/upload";
import { environment } from "./../../environments/environment";
import { AngularFirestore } from "angularfire2/firestore";
import { HttpClient } from "@angular/common/http";

import { Event } from "../types/event";

import * as _ from "lodash";

@Injectable()
export class AccomService {
  constructor(private afs: AngularFirestore, private http: HttpClient) {}

  async getHotels(event: Event) {
    let promise = new Promise((resolve, reject) => {
      let that = this;
      try {
        //prettier-ignore
        this.afs.collection("events").doc(event.id).collection("extras").doc("accommodation").valueChanges()
          .subscribe(doc => {
            let adoc = <any>doc;
            //prettier-ignore
            this.http.post(`${environment.obe.uri}Hotel/GetHotelsById`, {ids: _.take(_.map(adoc.accommodationPriority, "id"), environment.fibieOptions.maxProperties)})
              .subscribe(data => {
                //prettier-ignore sort
                data["properties"] = _.sortBy(data["properties"], item => adoc.accommodationPriority.map(e => e.id).indexOf(item.id));
                for (let property of data["properties"]) {
                  //prettier-ginore
                  let distance = this.getDistance(property.geoLocation.latitude, property.geoLocation.longitude, event.venue.latitude, event.venue.longitude );
                  //prettier-ginore
                  (distance < 0) ? property["distanceFromVenue"] = (distance * 1000).toFixed() + "m" : property["distanceFromVenue"] = distance.toFixed(1) + "km";
                }
                resolve(data["properties"]);
              });
          });
      } catch (error) {
        reject(error);
      }
    });
    return promise;
  }

  getRates(eventId: string, options: any) {
    if (options === undefined) {
    }
  }

  getDistance(lat1, lon1, lat2, lon2) {
    function deg2rad(deg) {
      return deg * (Math.PI / 180);
    }

    var R = 6371;
    var dLat = deg2rad(lat2 - lat1);
    var dLon = deg2rad(lon2 - lon1);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) *
        Math.cos(deg2rad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c; // Distance in km
    return d;
  }
}
