import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NgForm } from "@angular/forms";
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument
} from "angularfire2/firestore";
import { ToasterModule, ToasterService } from "angular5-toaster";
import { FormControl } from "@angular/forms";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { Event } from "../../../../types/event";
import { DateService } from "../../../../core/date.service";

import { environment } from "../../../../../environments/environment";
import * as _ from "lodash";
import * as moment from "moment";

@Component({
  selector: "app-accom-setup",
  templateUrl: "./accom-setup.component.html",
  styleUrls: ["./accom-setup.component.scss"]
})
export class AccomSetupComponent implements OnInit {
  eventId: string;
  event: Event = new Event();
  loading: boolean = true;

  accommodationPriority: any = [];
  properties: any = [];

  maxProperties: Number = environment.fibieOptions.maxProperties;

  constructor(
    private afs: AngularFirestore,
    private route: ActivatedRoute,
    private toasterService: ToasterService,
    private http: HttpClient,
    private dateService: DateService
  ) {}

  ngOnInit() {
    this.setupPage();
  }

  setupPage() {
    this.route.params.subscribe(params => {
      if (params.eventid) {
        this.eventId = params.eventid;
        this.afs
          .collection("events")
          .doc(this.eventId)
          .valueChanges()
          .subscribe(doc => {
            this.event = <Event>doc;
            this.loading = false;
          });

        this.afs
          .collection("events")
          .doc(this.eventId)
          .collection("extras")
          .doc("accommodation")
          .valueChanges()
          .subscribe(doc => {
            let adoc = <any>doc;
            if (adoc) this.accommodationPriority = adoc.accommodationPriority;
            this.getProperties();
          });
      }
    });

    this.formValidation();
  }

  async getProperties() {
    let that = this;
    this.http
      .post(`${environment.obe.uri}Hotel/GetHotelsByDistance`, {
        lat: this.event.venue.latitude,
        lng: this.event.venue.longitude,
        radius: 5,
        count: 20
      })
      .subscribe(data => {
        //remove any without photos
        data["properties"] = _.remove(data["properties"], n => n.images.length);
        //sort by saved
        if (that.accommodationPriority) {
          //console.log(JSON.stringify(that.accommodationPriority));
          //prettier-ignore
          data["properties"] = _.sortBy(data["properties"], item => that.accommodationPriority.map((e) => e.id).indexOf(item.id));
        }
        this.properties = data["properties"];
        this.getPropertyPricesAndReviews();
      });
  }

  getRatingDescription(rating) {
    switch (Math.round(rating)) {
      case 5:
        return "Excellent";
      case 4:
        return "Very Good";
      case 3:
        return "Good";
      case 2:
        return "Not Great";
      case 1:
        return "Not Good";
      default:
        "N/A";
    }
  }

  async getPropertyPricesAndReviews() {
    for (let property of this.properties) {
      this.http
        .post(`${environment.obe.uri}Hotel/GetRoomByHotelId`, {
          DateFrom: moment(this.event.starts.formatted).format("DD-MM-YYYY"),
          DateTo: moment(this.event.ends.formatted)
            .add(1, "days")
            .format("DD-MM-YYYY"),
          NumberOfAdults: 2,
          NumberOfChildren: 0,
          HotelId: property.id,
          Currency: this.event.currencyCode
        })
        .subscribe(data => {
          let rates: any = data;
          //console.log("rates:", data);
          if (rates.responseCode == 200) {
            property.fromRate = rates.lowestDailyRate;
          } else {
            property.fromRate = 0;
          }
        });
      //got the regex from OBE to match
      this.http
        .get(
          `${environment.obe.places.uri}hotels?name=${property.name
            .replace(new RegExp("&", "gi"), "")
            .replace(new RegExp("@", "gi"), "")
            .replace(new RegExp("  ", "gi"), "+")
            .replace(new RegExp(" ", "gi"), "+")}`
        )
        .subscribe(data => {
          let review: any = data;
          //get the highest rated
          if (review.reviews && review.reviews.length) {
            let maxReview: any = _.minBy(review.reviews, o => o.text.length); //get shortest review

            let reviewSummary: any = {
              rating: review.rating,
              ratingsTotal: review.ratingsTotal,
              initials: maxReview.authorName.match(/\b(\w)/g).join(""),
              comment: maxReview.text
            };

            property.reviewSummary = reviewSummary;
          } else {
            property.reviewSummary = {
              rating: "N/A",
              ratingsTotal: 0
            };
          }
        });
      //property.fromRate = 32;
    }
  }

  update(e) {
    this.properties = e;
  }

  async submit(form: NgForm) {
    let that = this;
    //this.event.accommodationPriority = [];
    let obj: any = [];
    if (this.event.showAccommodation) {
      _.forEach(this.properties, function(element, i) {
        obj.push({
          id: element.id,
          fromRate: element.fromRate,
          reviewSummary: element.reviewSummary
            ? {
                rating: element.reviewSummary.rating
                  ? element.reviewSummary.rating
                  : 0,
                ratingsTotal: element.reviewSummary.ratingsTotal
                  ? element.reviewSummary.ratingsTotal
                  : 0,
                initials: element.reviewSummary.initals
                  ? element.reviewSummary.initals
                  : "",
                comment: element.reviewSummary.comment
                  ? element.reviewSummary.comment
                  : ""
              }
            : {}
        });
      });
    }

    if (form.valid) {
      this.loading = true;
      this.afs
        .collection("events")
        .doc(this.event.id)
        .update(this.event)
        .then(ref => {
          this.loading = false;
          this.displayNotification(
            this.event.name,
            "Accommodation setup has been saved"
          );
        });
      this.afs
        .collection("events")
        .doc(this.event.id)
        .collection("extras")
        .doc("accommodation")
        .set({ accommodationPriority: obj });
    } else {
      console.log("form validation failed");
    }
  }

  formValidation() {
    var that = this;
    var forms = document.getElementsByClassName("needs-validation");
    var validation = Array.prototype.filter.call(forms, function(form) {
      form.addEventListener(
        "submit",
        function(event) {
          if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            //toast
            that.displayNotification(
              "Oops!",
              "Make sure you have filled all of the fields correctly.",
              true
            );
          }
          form.classList.add("was-validated");
        },
        false
      );
    });
  }

  displayNotification(title: string, msg: string, error: boolean = false) {
    this.toasterService.pop({
      type: error ? "error" : "success",
      title: title,
      body: msg,
      timeout: 2500
    });
  }
}
