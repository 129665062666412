import { Injectable } from "@angular/core";
//import { Upload } from "../types/upload";
import { environment } from "./../../environments/environment";

import * as AWS from "aws-sdk";

@Injectable()
export class Upload {
  file: File;
  fullnameWithPath: string;
  url: string;

  constructor(file: File, fullnameWithPath?: string) {
    this.file = file;
    this.fullnameWithPath = fullnameWithPath;
  }
}

export class UploadService {
  constructor() {}

  public pushFile(upload: Upload) {
    let promise = new Promise<Upload>((resolve, reject) => {
      const AWSService = AWS;
      const s3 = new AWSService.S3(environment.aws.credentials);
      s3.upload(
        {
          Key: upload.fullnameWithPath,
          Bucket: environment.aws.s3bucket,
          Body: upload.file,
          ACL: "public-read"
        },
        function(err, data) {
          if (!err) {
            upload.url = data.Location;
            resolve(upload);
          } else {
            reject(err.message);
            //console.log("test");
            //return Promise.resolve(err);
          }
        }
      );
    });
    return promise;

    // const AWSService = AWS;
    // const s3 = new AWSService.S3(environment.aws.credentials);
    // s3.upload(
    //   {
    //     Key: upload.fullnameWithPath,
    //     Bucket: environment.aws.s3bucket,
    //     Body: upload.file,
    //     ACL: "public-read"
    //   },
    //   function(err, data) {
    //     if (!err) {
    //       upload.url = data.Location;
    //     } else {
    //       console.log("test");
    //       return Promise.resolve(err);
    //     }
    //   }
    // );
    // console.log("test2");
    // return Promise.resolve(upload);
  }
}
