import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-tickets",
  templateUrl: "./tickets.component.html",
  styleUrls: ["./tickets.component.scss"]
})
export class TicketsComponent implements OnInit {
  tickets: any = [
    {
      onlineEvent: true,
      category: "Technology",
      freeOptions: true,
      ageRestrictions: false,
      minAge: false,
      earlyBird: false,
      slug: "live-stream/fibie-online-conference-mar2020",
      smallPhoto:
        "https://images.unsplash.com/photo-1531297484001-80022131f5a1?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1907&q=80",
      starts: {
        epoc: 1584331854
      },
      name: "Fibie Online Conference",
      descriptionShort:
        "The events industry is changing at a rapid pace. This informative webinar features topics led by event industry experts that will provide you with the insights and knowledge you need to plan and produce hybrid meetings."
    }
  ];

  constructor() {}

  ngOnInit() {}
}
