import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "./../../../environments/environment";

import * as marked from "marked";
import * as _ from "lodash";

@Injectable()
export class DirectusService {
  api: string;

  constructor(private http: HttpClient) {
    this.api = environment.directus.uri;
  }

  getPageByUrl(url: string) {
    return this.http.get(this.api + "/items/pages?filter[url][eq]=" + url);
  }

  getFaqTypes() {
    return this.http.get(this.api + "/items/faq_types?sort=id");
  }

  getTags() {
    let promise = new Promise<any>((resolve, reject) => {
      let tags = [];
      this.http.get<any>(this.api + "/items/tags").subscribe(res => {
        tags = _.map(res.data, function(element, idx) {
          return element.tag;
        });
        if (tags.length) {
          resolve(tags);
        } else {
          reject(null);
        }
      });
    });
    return promise;
  }

  getFaqs(faqtype: number = 1) {
    return this.http.get(
      this.api +
        "/items/faq?sort=-priority&status=published&filter[faqtype][eq]=" +
        faqtype
    );
  }

  //to do: replace others and use this for standard collections
  getCollection(collection: string) {
    let promise = new Promise<any>((resolve, reject) => {
      let results = [];
      this.http.get<any>(this.api + `/items/${collection}`).subscribe(res => {
        results = _.map(res.data, function(element, idx) {
          return element;
        });
        if (results.length) {
          resolve(results);
        } else {
          reject(null);
        }
      });
    });
    return promise;
  }
}
