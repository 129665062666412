
import {map} from 'rxjs/operators';
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  AngularFirestore,
  AngularFirestoreCollection
} from "angularfire2/firestore";
import { Event } from "../../../../types/event";
import { Registration } from "../../../../types/registration";

import { AccomService } from "../../../../services/accom.service";

import * as moment from "moment";

declare var $: any;

@Component({
  selector: "app-results",
  templateUrl: "./results.component.html",
  styleUrls: ["./results.component.scss"]
})
export class ResultsComponent implements OnInit {
  registrationId: string;
  registration: any;
  event: any;
  slug: string;
  dates: any;

  properties: any = [];

  propertyHoverId = 0;

  icon = {
    url: "/assets/images/maps-and-flags.svg",
    scaledSize: {
      width: 38,
      height: 38
    }
  };

  hotelicon = {
    url: "/assets/images/maps-and-flags-hotel.svg",
    scaledSize: {
      width: 38,
      height: 38
    }
  };

  options: any = {
    startDatePlaceholderText: "Check-in",
    endDatePlaceholderText: "Check-out",
    showClearDates: true,
    customInputIcon: false,
    displayFormat: "DD MMMM YYYY",
    hideKeyboardShortcutsPanel: true
  };

  constructor(
    private route: ActivatedRoute,
    private afs: AngularFirestore,
    private accomservice: AccomService
  ) {}

  ngOnInit() {
    //Add validation and session information in here
    //this.registrationId = this.route.snapshot.paramMap.get("id");
    this.route.params.subscribe(params => {
      this.slug = params["slug"];
      this.afs
        .collection<Event>("events", ref => ref.where("slug", "==", this.slug))
        .snapshotChanges().pipe(
        map(actions => {
          return actions.map(a => {
            const data = a.payload.doc.data() as any;
            const id = a.payload.doc.id;
            return { id, ...data };
          });
        }))
        .subscribe(data => {
          if (data.length > 0) {
            this.event = data[0];

            this.dates = {
              start: moment(this.event.starts.formatted),
              end: moment(this.event.ends.formatted).add(1, "days")
            };

            this.accomservice
              .getHotels(this.event)
              .then(res => {
                this.properties = res;
                console.log(res);
              })
              .catch(err => {
                console.log(err);
              });
          }
        });
    });
  }

  setProperty(e) {
    this.property = e;
  }

  mapstyle = [
    {
      featureType: "all",
      stylers: [
        {
          saturation: 0
        },
        {
          hue: "#e7ecf0"
        }
      ]
    },
    {
      featureType: "road",
      stylers: [
        {
          saturation: -70
        }
      ]
    },
    {
      featureType: "transit",
      stylers: [
        {
          visibility: "off"
        }
      ]
    },
    {
      featureType: "poi",
      stylers: [
        {
          visibility: "off"
        }
      ]
    },
    {
      featureType: "water",
      stylers: [
        {
          visibility: "simplified"
        },
        {
          saturation: -60
        }
      ]
    }
  ];

  property: any = null;
  reviews: any = null;
  rates: any = null;
}
