import { Component, OnInit } from "@angular/core";

import * as AOS from "aos";

@Component({
  selector: "app-standard-content",
  templateUrl: "./standard-content.component.html",
  styleUrls: ["./standard-content.component.scss"]
})
export class StandardContentComponent implements OnInit {
  constructor() {}

  ngOnInit() {
    AOS.init();
  }
}
