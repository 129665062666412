import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, ParamMap, Params, Router } from "@angular/router";
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument
} from "angularfire2/firestore";
import { ChartType, ChartOptions } from "chart.js";

import { Event } from "../../../../types/event";

@Component({
  selector: "app-event-dash",
  templateUrl: "./event-dash.component.html",
  styleUrls: ["./event-dash.component.scss"]
})
export class EventDashComponent implements OnInit {
  eventId: string;
  event: Event = new Event();
  nextStepMessage: any = null;

  public lineChartData: Array<any> = [
    [5, 10, 8, 6, 8, 12, 15],
    [4, 15, 16, 9, 10, 14, 11],
    [1, 4, 5, 2, 3, 3, 4]
  ];
  public lineChartLabels: Array<any> = [
    "Thu",
    "Fri",
    "Sat",
    "Sun",
    "Mon",
    "Tue",
    "Wed"
  ];
  public lineChartType: string = "line";
  public lineChartOptions: any = {
    responsive: true,
    maintainAspectRatio: false
  };

  public pieChartData: number[] = [300, 500, 100, 50, 50, 320];
  public pieChartData1: number[] = [300, 500, 400];
  public pieChartData2: number[] = [30, 70, 20];
  public pieChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      //position: 'top',
    }
  };
  public pieChartType: ChartType = "pie";
  public pieChartColors = [
    {
      backgroundColor: [
        "#BC2F17",
        "#06374F",
        "#0D91B7",
        "#9EB466",
        "#E5C536",
        "#EC6C12"
      ],
      borderColor: ["#eee"]
    }
  ];
  public pieChartColors2 = [
    {
      backgroundColor: ["green", "purple", "orange"]
    }
  ];

  constructor(
    private route: ActivatedRoute,
    private afs: AngularFirestore,
    private router: Router
  ) {}

  ngOnInit() {
    this.setupPage();
  }

  setupPage() {
    this.route.params.subscribe(params => {
      if (params.eventid) {
        this.eventId = params.eventid;
        this.afs
          .collection("events")
          .doc(this.eventId)
          .valueChanges()
          .subscribe(doc => {
            this.event = <Event>doc;
          });
      } else {
        this.router.navigate(["/account"]);
      }
    });
  }

  incomplete(): boolean {
    if (this.event) {
      if (!this.event.paymentSetup) {
        this.nextStepMessage = {
          title: "You're not quite there yet!",
          message: "Don't forget to create your tickets!"
        };
        return true;
      }
    }
    return false;
  }

  eventLinkToClipboard() {
    const el = document.createElement("textarea");
    el.value =
      "http://staging-fibie-web.s3-website-ap-southeast-2.amazonaws.com/event/" +
      this.event.slug;
    el.setAttribute("readonly", "");
    el.style.position = "absolute";
    el.style.left = "-9999px";
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  }

  public chartClicked(e: any): void {
    console.log(e);
  }

  public chartHovered(e: any): void {
    console.log(e);
  }
}
