import { environment } from "./../../../../../environments/environment";
import {
  Component,
  NgZone,
  NgModule,
  OnInit,
  ElementRef,
  ViewChild
} from "@angular/core";
import { NgForm } from "@angular/forms";
import { AuthService } from "../../../../core/auth/auth.service";
import { ActivatedRoute, ParamMap, Params, Router } from "@angular/router";
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument
} from "angularfire2/firestore";
import { ToasterModule, ToasterService } from "angular5-toaster";
import { MapsAPILoader } from "@agm/core";
import { FormControl } from "@angular/forms";
import { DirectusService } from "../../../../core/directus/directus.service";

import { IMyDpOptions, IMyDateModel } from "mydatepicker";
import { MomentModule } from "angular2-moment";
import * as moment from "moment";

import { Event } from "../../../../types/event";
import { Venue } from "../../../../types/venue";

declare var google: any;
declare var $: any;
import * as _ from "lodash";
import * as slugify from "slugify";

import {} from "googlemaps";

@Component({
  selector: "app-event",
  templateUrl: "./event.component.html",
  styleUrls: ["./event.component.scss"]
})
export class EventComponent implements OnInit {
  eventId: string;
  event: Event = new Event();
  user: any = {};

  tagSuggestions: any = [];

  loading: boolean = false;
  newEvent: boolean = true;
  firstEvent: boolean = false;
  showAddressEditor: boolean = false;
  addressSearch: string;
  editorOptions: Object = {
    height: 400,
    max_height: 500,
    menubar: false,
    toolbar:
      "undo redo | formatselect | bold italic backcolor | \
      alignleft aligncenter alignright alignjustify | blockquote | \
      bullist numlist outdent indent | removeformat | help"
  };

  // editorOptions: Object = {
  //   charCounterCount: false,
  //   heightMax: 400,
  //   toolbarButtonsXS: ["bold", "italic", "underline", "insertLink"],
  //   toolbarButtons: [
  //     "fullscreen",
  //     "bold",
  //     "italic",
  //     "underline",
  //     "strikeThrough",
  //     "subscript",
  //     "superscript",
  //     "|",
  //     "fontFamily",
  //     "fontSize",
  //     "color",
  //     "paragraphStyle",
  //     "lineHeight",
  //     "|",
  //     "paragraphFormat",
  //     "align",
  //     "formatOL",
  //     "formatUL",
  //     "outdent",
  //     "indent",
  //     "quote",
  //     "|",
  //     "insertLink",
  //     "insertTable",
  //     "|",
  //     "insertHR",
  //     "clearFormatting",
  //     "html",
  //     "|",
  //     "undo",
  //     "redo"
  //   ]
  // };

  @ViewChild("searchAddress") public searchAddressElementRef: ElementRef;

  public searchAddressControl: FormControl = new FormControl();

  //https://github.com/kekeh/mydatepicker
  public myDatePickerOptions: IMyDpOptions = {
    dateFormat: "dd mmm yyyy",
    editableDateField: false,
    openSelectorOnInputClick: true,
    showTodayBtn: false,
    showClearDateBtn: false
    //disableUntil: { year: 2018, month: 3, day: 15 },
    //disableSince: { year: 2018, month: 9, day: 15 }
  };

  constructor(
    public auth: AuthService,
    private toasterService: ToasterService,
    private afs: AngularFirestore,
    private route: ActivatedRoute,
    private ngZone: NgZone,
    private mapsAPILoader: MapsAPILoader,
    private router: Router,
    private directusService: DirectusService
  ) {}

  ngOnInit() {
    this.userDetails();
    this.setupPage();
  }

  userDetails() {
    this.auth.user.subscribe(user => {
      this.user = user;
    });
  }

  setupPage() {
    this.route.params.subscribe(params => {
      if (
        params.eventid &&
        params.eventid != "new" &&
        params.eventid != "first"
      ) {
        this.eventId = params.eventid;
        this.afs
          .collection("events")
          .doc(this.eventId)
          .valueChanges()
          .subscribe(doc => {
            this.event = <Event>doc;
            this.newEvent = false;
            this.showAddressEditor = true;
          });
      } else {
        this.newEvent = true;
        if (params.eventid == "first") this.firstEvent = true;
      }
    });

    this.directusService.getTags().then(res => (this.tagSuggestions = res));

    this.mapsAPILoader.load().then(() => {
      let autocomplete = new google.maps.places.Autocomplete(
        this.searchAddressElementRef.nativeElement,
        {
          //types: ["establishment"],
          componentRestrictions: {
            country: environment.googleMaps.countryRestrictions
          }
        }
      );
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(res => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          // console.log("place.address_components:", JSON.stringify(place));
          // console.log("place.address_components:", JSON.stringify(place.address_components));
          this.event.venue.name = place.name;
          this.event.venue.googlePlaceId = place.place_id;
          //this.event.venue.reviews = place.reviews;

          for (let a = 0; a < place.address_components.length; a++) {
            if (_.includes(place.address_components[a].types, "street_number"))
              this.event.venue.address1 = place.address_components[a].long_name;
            if (_.includes(place.address_components[a].types, "route"))
              this.event.venue.address1 +=
                " " + place.address_components[a].long_name;
            if (_.includes(place.address_components[a].types, "locality"))
              this.event.venue.city = place.address_components[a].long_name;
            if (
              _.includes(
                place.address_components[a].types,
                "administrative_area_level_1"
              )
            )
              this.event.venue.state = place.address_components[a].long_name;
            if (_.includes(place.address_components[a].types, "country"))
              this.event.venue.country = place.address_components[a].long_name;
            if (_.includes(place.address_components[a].types, "postal_code"))
              this.event.venue.postcode = place.address_components[a].long_name;
          }

          this.event.venue.latitude = place.geometry.location.lat();
          this.event.venue.longitude = place.geometry.location.lng();

          this.showAddressEditor = true;
          //this.zoom = 12;
        });
      });
    });
    this.formValidation();
  }

  formValidation() {
    var that = this;
    var forms = document.getElementsByClassName("needs-validation");
    var validation = Array.prototype.filter.call(forms, function(form) {
      form.addEventListener(
        "submit",
        function(event) {
          if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            //toast
            that.displayNotification(
              "Oops!",
              "Make sure you have filled all of the fields correctly.",
              true
            );
          }
          form.classList.add("was-validated");
        },
        false
      );
    });
  }

  async submitEvent(form: NgForm) {
    if (form.valid) {
      if (this.newEvent) {
        this.createEvent();
      } else {
        this.saveEvent();
      }
    }
  }

  createEvent() {
    this.loading = true;

    this.event.venue = Object.assign({}, this.event.venue);
    this.event.slug = slugify.default(this.event.name + "-" + moment().unix(), {
      replacement: "-",
      lower: true
    });
    this.event.userRef = this.afs.doc(`users/${this.user.uid}`).ref;

    this.afs
      .collection("events")
      .add(Object.assign({}, this.event))
      .then(ref => {
        ref.set({ id: ref.id }, { merge: true }).then(() => {
          this.displayNotification(this.event.name, "Has been created");
          this.loading = false;
          this.newEvent = false;
          this.router.navigate(["/account/my/events/" + ref.id + "/dash"]);
        });
      });
  }

  saveEvent() {
    this.loading = true;
    this.event.venue = Object.assign({}, this.event.venue);
    this.afs
      .collection("events")
      .doc(this.event.id)
      .update(this.event)
      .then(ref => {
        this.loading = false;
        this.displayNotification(
          this.event.name,
          "Event details have been saved"
        );
        // setTimeout(() => {
        //   this.router.navigate(["/account/my/events/" + this.event.id + "/dash"]);
        // }, 1000);
      });
  }

  setEventApprovalStatus(live: boolean) {
    this.afs
      .collection("events")
      .doc(this.event.id)
      .set({ approved: live }, { merge: true })
      .then(ref => {
        if (live) {
          this.displayNotification(this.event.name, "Is now live!");
          $("#goLiveModal").modal("toggle");
        } else {
          this.displayNotification(this.event.name, "Has been taken offline");
        }
      });
  }

  showGoLiveModal() {
    document.getElementById("btnLiveModal").click();
  }

  displayNotification(title: string, msg: string, error: boolean = false) {
    this.toasterService.pop({
      type: error ? "error" : "success",
      title: title,
      body: msg,
      timeout: 2500
    });
  }

  daysApart(): number {
    return (
      moment(this.event.ends.jsdate).diff(this.event.starts.jsdate, "day") + 1
    );
  }

  isMultipleDates() {
    return this.event.ends && this.event.starts
      ? moment(this.event.ends.jsdate).isAfter(this.event.starts.jsdate, "day")
      : false;
  }

  setMultipleDates(e) {
    this.event.multiDaySingleEvent = e;
  }

  onDateChanged(e: IMyDateModel, d) {
    // event properties are: event.date, event.jsdate, event.formatted and event.epoc
    switch (d) {
      case "start":
        if (!this.event.ends) {
          this.event.ends = e;
          return;
        }
        if (this.event.ends.epoc < e.epoc) this.event.ends = e;
        break;
      case "end":
        if (!this.event.starts) {
          this.event.starts = e;
          return;
        }
        if (this.event.starts.epoc > e.epoc) this.event.starts = e;
        break;
    }
  }

  setPrivate(e) {
    console.log(e);
    this.event.private = e;
  }

  markerDragEnd(m: any, $event: MouseEvent) {
    let e = <any>$event;
    this.event.venue.latitude = e.coords.lat;
    this.event.venue.longitude = e.coords.lng;
  }
}
