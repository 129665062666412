import { Component, OnInit } from "@angular/core";
import { DirectusService } from "../../../core/directus/directus.service";

import * as AOS from "aos";
import * as _ from "lodash";

@Component({
  selector: "app-faqs",
  templateUrl: "./faqs.component.html",
  styleUrls: ["./faqs.component.scss"]
})
export class FaqsComponent implements OnInit {
  constructor(private directusService: DirectusService) {}

  faqs: any = [];
  faqTypes: any = [];
  faqType: number = 1;
  faqTypeName: string;

  ngOnInit() {
    AOS.init();

    this.getFaqTypes();
    this.getFaqs();
  }

  getFaqTypes() {
    this.directusService.getFaqTypes().subscribe(data => {
      this.faqTypes = data;
      this.faqTypes = this.faqTypes.data;

      //console.log("this.faqTypes:", JSON.stringify(this.faqTypes));
    });
  }

  selectFaqType(faqTypeId) {
    this.faqType = faqTypeId;
    this.getFaqs();
  }

  getFaqs() {
    this.directusService.getFaqs(this.faqType).subscribe(data => {
      if (data) {
        this.faqs = data;
        this.faqs = this.faqs.data;

        this.faqTypeName = _.find(this.faqTypes, ["id", this.faqType]).usertype;
      }
    });
  }
}
