import { Component } from "@angular/core";
import {
  FormGroup,
  AbstractControl,
  FormBuilder,
  Validators
} from "@angular/forms";
import { AuthService } from "../../../core/auth/auth.service";

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.scss"]
})
export class ForgotPasswordComponent {
  //   form: FormGroup;
  //   email: AbstractControl;
  //   @Output() onSuccess = new EventEmitter();
  //   @Output() onError = new EventEmitter();
  //  constructor(private authService: AuthService, private fb: FormBuilder) {
  //   this.form = fb.group({
  //       'email': ['', Validators.required]
  //   });
  //   this.email = this.form.controls['email'];
  //}
  //   reset() {
  //       if (this.form.valid) {
  //           this.authService.sendPasswordResetEmail(this.email.value)
  //               .subscribe(
  //                   () => {
  //                       this.onSuccess.emit();
  //                       this.form.reset();
  //                   },
  //                   err => this.onError.emit(err)
  //               );
  //       }
  //   }
}
