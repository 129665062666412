import { Injectable } from "@angular/core";
import { DateService } from "./date.service";

@Injectable()
export class StatusService {
  constructor(private dateService: DateService) {}

  getStatus(event): any {
    let styles = {
      badgeStyle: "badge upcoming",
      badgeText: "Happening"
    };

    if (!event.approved) {
      styles.badgeStyle = "badge under-construction";
      styles.badgeText = "Under Construction";
    } else {
      if (!this.dateService.isPast(event)) {
        styles.badgeStyle = "badge happening";
        styles.badgeText = "Upcoming";
      } else {
        styles.badgeStyle = "badge past";
        styles.badgeText = "Past";
        //}
      }
    }
    return styles;
  }
}
