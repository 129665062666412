import { Component, Inject, forwardRef, Input } from "@angular/core";
import { BaseWidget, NgAisInstantSearch } from "angular-instantsearch";
import { connectToggle } from "instantsearch.js/es/connectors";

@Component({
  selector: "ais-fibie-filter",
  template: `
    <div
      class="filter-box"
      [ngClass]="{ selected: state.value && state.value.isRefined }"
      (click)="state.refine(state.value)"
    >
      <img [src]="image" class="filter-icon" />
      <div class="option"><ng-content></ng-content></div>
    </div>
  `,
  styleUrls: ["../search.component.scss"]
})
export class MenuSelect extends BaseWidget {
  @Input() attribute;
  @Input() filtervalue;
  @Input() image;
  //https://codesandbox.io/s/3xyr76oqlp
  state: {
    value: { name: string; count: number; isRefined: boolean };
    createURL: () => string;
    refine: (value: string) => undefined;
    attributeName: string;
    //isRefined: boolean;
  };

  constructor(
    @Inject(forwardRef(() => NgAisInstantSearch))
    public instantSearchParent
  ) {
    super("MenuSelect");
    //this.state.refine(true);
    //console.log("this.state:", this.state);
  }

  public ngOnInit() {
    this.createWidget(connectToggle, {
      label: "Free",
      attributeName: this.attribute,
      values: { on: this.filtervalue, off: undefined }
    });
    super.ngOnInit();
  }
}
