
import {map} from 'rxjs/operators';
import { Component, OnInit } from "@angular/core";
import { AuthService } from "../../core/auth/auth.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-signup",
  templateUrl: "./signup.component.html",
  styleUrls: ["../login/login.component.scss", "./signup.component.scss"]
})
export class SignupComponent implements OnInit {
  name: string;
  errorMessage: string;
  wait: boolean = false;

  constructor(private router: Router, public auth: AuthService) {
    this.auth.user.pipe(
      map(user => {
        if (user) {
          this.wait = false;
          this.router.navigate(["/account"]);
        }
      }))
      .subscribe();
  }

  ngOnInit() {}

  join() {
    this.wait = true;
    this.errorMessage = "";
    this.auth
      .join()
      .then(ret => {})
      .catch(err => {
        this.wait = false;
        this.errorMessage = err.message;
      });
  }
}
