import { Component, OnInit, Input } from "@angular/core";
import { Event } from "../../../types/event";
import { DateService } from "../../../core/date.service";

@Component({
  selector: "app-card",
  templateUrl: "./card.component.html",
  styleUrls: ["./card.component.scss"]
})
export class CardComponent implements OnInit {
  @Input() event: Event;
  constructor(private dateService: DateService) {}

  ngOnInit() {
    //console.log("event:", this.event);
  }
}
