import { Observable ,  of } from "rxjs";
import { switchMap } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

import * as firebase from "firebase/app";
import { AngularFireAuth } from "angularfire2/auth";
import {
  AngularFirestore,
  AngularFirestoreDocument
} from "angularfire2/firestore";
import "rxjs/add/operator/switchMap";

import { User, UserPreferences } from "../../types/user";

@Injectable()
export class AuthService {
  user: Observable<User>;

  credentials: any = { displayName: "", email: "", password: "" };

  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router
  ) {
    this.user = this.afAuth.authState.pipe(
      switchMap(user => {
        if (user) {
          return this.afs.doc<User>(`users/${user.uid}`).valueChanges();
        } else {
          return of(null);
        }
      })
    );
  }

  login(provider) {
    switch (provider) {
      case "google":
        return this.oAuthLogin(new firebase.auth.GoogleAuthProvider());
      case "twitter":
        return this.oAuthLogin(new firebase.auth.TwitterAuthProvider());
      case "facebook":
        return this.oAuthLogin(new firebase.auth.FacebookAuthProvider());
      case "email":
        return this.oAuthLogin(new firebase.auth.EmailAuthProvider());
      default:
        return this.oAuthEmailLogin();
    }
  }

  join() {
    const provider = new firebase.auth.EmailAuthProvider();

    if (this.credentials.displayName == "")
      throw new Error("Please enter your name");

    return this.afAuth.auth
      .createUserWithEmailAndPassword(
        this.credentials.email,
        this.credentials.password
      )
      .then(user => {
        user
          .updateProfile({ displayName: this.credentials.displayName })
          .then(() => {
            this.updateUserData(user);
          });
        // }).catch((error) => {
        //   console.log('error creating account:', error);
      });
  }

  private oAuthLogin(provider) {
    return this.afAuth.auth.signInWithPopup(provider).then(credential => {
      if (credential.additionalUserInfo.isNewUser) {
        console.log("new user:", credential.user);
        this.updateUserData(credential.user);
      } else {
        console.log("existing user... carry on");
      }
      this.router.navigate(["/account"]);
    });
  }

  private oAuthEmailLogin() {
    const provider = new firebase.auth.EmailAuthProvider();
    return this.afAuth.auth
      .signInWithEmailAndPassword(
        this.credentials.email,
        this.credentials.password
      )
      .then(credential => {
        this.updateUserData(credential.user);
        // this.router.navigate(['/home']);
      })
      .catch(error => {
        console.log("error logging in:", error);
      });
  }

  public updateUserData(user) {
    // Sets user data to firestore on login
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(
      `users/${user.uid}`
    );

    const data: User = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL
    };

    if (user.emailPreferences)
      data.emailPreferences = Object.assign({}, user.emailPreferences);

    return userRef.set(data, { merge: true });
  }

  public updateUserPreferences(user, preferences) {
    const ref: AngularFirestoreDocument<any> = this.afs.doc(
      `users/${user.uid}`
    );
    return ref.set(
      { preferences: Object.assign({}, preferences) },
      { merge: true }
    );
  }

  public updateUserBanking(user, banking) {
    const ref: AngularFirestoreDocument<any> = this.afs.doc(
      `users/${user.uid}`
    );
    return ref.set({ banking: Object.assign({}, banking) }, { merge: true });
  }

  // public updateUserEmailPreferences(user, emailPreferences) {
  //   const ref: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}/emailPreferences`);
  //   return ref.set(emailPreferences, { merge: true });
  // }

  signOut() {
    this.afAuth.auth.signOut().then(() => {
      this.router.navigate(["/"]);
    });
  }
}
