import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from '../../../core/auth/auth.service';

import * as AOS from 'aos';
declare var $: any;

@Component({
  selector: 'fibie-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() useLightTheme: boolean = false;
  @Input() useDarkAccountTheme: boolean = false;
  
  constructor(public auth: AuthService) {
    this.templateListener();
    
  }
  
  ngOnInit() {
  }

  signOut(){
    console.log('logging user out');
    this.auth.signOut();
  }

  templateListener(){
    $(window).scroll(function () {
      if ($(window).scrollTop() >= 100) {
          $('.topbar').addClass('fixed-header animated slideInDown');
      } else {
          $('.topbar').removeClass('fixed-header animated slideInDown');
      }
    });
    AOS.init();
  }

}
