import { Component, OnInit } from "@angular/core";
import { AuthService } from "../../../../core/auth/auth.service";
import { UserPreferences } from "../../../../types/user";
import { ToasterService } from "angular5-toaster";

@Component({
  selector: "app-my-branding",
  templateUrl: "./my-branding.component.html",
  styleUrls: ["./my-branding.component.scss"]
})
export class MyBrandingComponent implements OnInit {
  user: any = {};
  preferences: UserPreferences = new UserPreferences();

  loading: boolean = false;

  userProfileImage: any = {
    url: "",
    s3name: ""
  };

  constructor(public auth: AuthService, private toasterService: ToasterService) {}

  ngOnInit() {
    this.auth.user.subscribe(user => {
      this.user = user;
      if (user.preferences) this.preferences = user.preferences;

      this.userProfileImage.url = this.preferences.backgroundImage;
      this.userProfileImage.s3name = "profiles/branding/" + this.user.uid;
    });
  }

  onNewPhoto(url) {
    this.preferences.backgroundImage = url;
  }

  onRemovePhoto() {
    this.userProfileImage.url = null;
    this.preferences.backgroundImage = null;
  }

  save() {
    this.loading = true;

    this.auth
      .updateUserPreferences(this.user, this.preferences)
      .then(() => {
        this.toasterService.pop({
          type: "success",
          title: "Success",
          body: "You changes are now live",
          timeout: 3500
        });
        this.loading = false;
      })
      .catch(err => {
        this.toasterService.pop({
          type: "error",
          title: "Error saving your details",
          body: err.message,
          timeout: 3500
        });
        this.loading = false;
      });
  }
}
