import { BrowserModule } from "@angular/platform-browser";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { HttpClientModule, HttpClient } from "@angular/common/http";

import { environment } from "../environments/environment";

import { AngularAgoraRtcModule, AgoraConfig } from "angular-agora-rtc";

import { AngularFireModule } from "angularfire2";
import { AngularFirestoreModule } from "angularfire2/firestore";
import { NgAisModule } from "angular-instantsearch";
import { AgmCoreModule, MapsAPILoader } from "@agm/core";
import { AgmSnazzyInfoWindowModule } from "@agm/snazzy-info-window";
import { ToasterModule, ToasterService } from "angular5-toaster";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MyDatePickerModule } from "mydatepicker";
import { ChartsModule } from "ng2-charts/ng2-charts";
// import { CountDown } from "ng4-date-countdown-timer";
import { QRCodeModule } from "angularx-qrcode";
import { ColorPickerModule } from "ngx-color-picker";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { NgxMaskModule } from "ngx-mask";
import { HomeComponent } from "./components/home/home.component";
import { NotFoundComponent } from "./components/not-found/not-found.component";
import { AccountComponent } from "./components/account/account.component";
import { LoaderComponent } from "./components/common/loader.component";
import { DropUploaderComponent } from "./components/common/dropuploader.component";

import { DirectusService } from "./core/directus/directus.service";
import { UploadService } from "./services/upload.service";
import { AccomService } from "./services/accom.service";
import { NgxPaginationModule } from "ngx-pagination";
//import { FroalaEditorModule, FroalaViewModule } from "angular-froala-wysiwyg";
import { EditorModule } from "@tinymce/tinymce-angular";
import { TagInputModule } from "ngx-chips";
import { AngularReactDatesModule } from "angular-react-dates";

import { CoreModule } from "./core/core.module";
import { DetailsComponent } from "./components/account/details/details.component";
import { LoginComponent } from "./components/login/login.component";
import { HeaderComponent } from "./components/template/header/header.component";
import { FooterComponent } from "./components/template/footer/footer.component";
import { StandardContentComponent } from "./components/content/standard-content/standard-content.component";
import { SearchComponent } from "./components/search/search.component";
import { MenuSelect } from "./components/search/filter/filter.component";
import { DetailComponent } from "./components/detail/detail.component";
import { BookComponent } from "./components/book/book.component";
import { ConfirmComponent } from "./components/confirm/confirm.component";
import { DemoComponent } from "./components/demo/demo.component";
import { GeoService } from "./core/geo/geo.service";
import { StatusService } from "./core/status.service";
import { DateService } from "./core/date.service";

import { SignupComponent } from "./components/signup/signup.component";
import {
  EventsComponent,
  EventCardComponent,
} from "./components/account/events/events.component";
import { MyComponent } from "./components/account/my/my.component";
import { EventComponent } from "./components/account/events/event/event.component";
import { EventDashComponent } from "./components/account/events/event-dash/event-dash.component";
import { ForgotPasswordComponent } from "./components/login/forgot-password/forgot-password.component";
import { DesignComponent } from "./components/account/events/design/design.component";
import { TicketsComponent } from "./components/account/tickets/tickets.component";
import { TicketTypesComponent } from "./components/account/events/tickets/tickets.component";
import { CardComponent } from "./components/search/card/card.component";
import { DateSuffixPipe } from "./pipes/date-suffix.pipe";
import { TestComponent } from "./components/test/test.component";
import { AccomCardComponent } from "./components/accommodation/accomcard.component";
import { PropertyDetailsComponent } from "./components/accommodation/search/property-details/property-details.component";
import { CmsComponent } from "./components/content/cms/cms.component";
import { FaqsComponent } from "./components/content/faqs/faqs.component";
import { FiledropDirective } from "./directives/filedrop.directive";
import { MyBrandingComponent } from "./components/account/events/my-branding/my-branding.component";
import { MyPayoutsComponent } from "./components/account/events/my-payouts/my-payouts.component";
import { MyBankingComponent } from "./components/account/events/my-banking/my-banking.component";
import { PaymentComponent } from "./components/account/events/payment/payment.component";
import { ResultsComponent } from "./components/accommodation/search/results/results.component";
import { TourComponent } from "./components/account/events/tour/tour.component";
import { AccomSetupComponent } from "./components/account/events/accom-setup/accom-setup.component";
import { SocialSetupComponent } from "./components/account/events/social-setup/social-setup.component";
import { DiscountsSetupComponent } from "./components/account/events/discounts-setup/discounts-setup.component";
import { InvitesSetupComponent } from "./components/account/events/invites-setup/invites-setup.component";
import { AttendeeReportComponent } from "./components/account/events/attendee-report/attendee-report.component";
import { CheckinReportComponent } from "./components/account/events/checkin-report/checkin-report.component";
import { NameBadgesComponent } from "./components/account/events/name-badges/name-badges.component";
import { EmailAttendeesComponent } from "./components/account/events/email-attendees/email-attendees.component";
import { SortableDirective } from "./directives/sortable.directive";
import { LiveStreamComponent } from "./components/live-stream/live-stream.component";

export const firebaseConfig = environment.firebase;

const agoraConfig: AgoraConfig = {
  AppID: "7d64d4a0790c4baaa532aa8c87791b34",
};

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NotFoundComponent,
    AccountComponent,
    DetailsComponent,
    LoginComponent,
    HeaderComponent,
    FooterComponent,
    StandardContentComponent,
    SearchComponent,
    DetailComponent,
    BookComponent,
    ConfirmComponent,
    DemoComponent,
    SignupComponent,
    EventsComponent,
    EventCardComponent,
    TicketsComponent,
    MyComponent,
    EventComponent,
    EventDashComponent,
    ForgotPasswordComponent,
    DesignComponent,
    TicketTypesComponent,
    CardComponent,
    // CountDown,
    DateSuffixPipe,
    TestComponent,
    AccomCardComponent,
    PropertyDetailsComponent,
    CmsComponent,
    FaqsComponent,
    MenuSelect,
    LoaderComponent,
    FiledropDirective,
    DropUploaderComponent,
    MyBrandingComponent,
    MyPayoutsComponent,
    MyBankingComponent,
    PaymentComponent,
    ResultsComponent,
    TourComponent,
    AccomSetupComponent,
    SocialSetupComponent,
    DiscountsSetupComponent,
    InvitesSetupComponent,
    AttendeeReportComponent,
    CheckinReportComponent,
    NameBadgesComponent,
    EmailAttendeesComponent,
    SortableDirective,
    LiveStreamComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    ColorPickerModule,
    AngularAgoraRtcModule.forRoot(agoraConfig),
    AngularFireModule.initializeApp(environment.firebase, "fibie-b726e"),
    AgmCoreModule.forRoot({
      apiKey: environment.googleMaps.key,
      libraries: ["places"],
    }),
    AgmSnazzyInfoWindowModule,
    AppRoutingModule,
    CoreModule,
    ToasterModule,
    BrowserAnimationsModule,
    MyDatePickerModule,
    ChartsModule,
    QRCodeModule,
    NgAisModule.forRoot(),
    NgxPaginationModule,
    NgxMaskModule.forRoot(),
    EditorModule,
    // FroalaEditorModule.forRoot(),
    // FroalaViewModule.forRoot(),
    TagInputModule,
    AngularReactDatesModule.forRoot(),
  ],
  providers: [
    GeoService,
    StatusService,
    DateService,
    DirectusService,
    UploadService,
    AccomService,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}
