import { Component, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { AuthService } from "../../../../core/auth/auth.service";
import { ActivatedRoute, ParamMap, Params, Router } from "@angular/router";
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument
} from "angularfire2/firestore";
import { ToasterModule, ToasterService } from "angular5-toaster";

import { FormControl } from "@angular/forms";

import { Event } from "../../../../types/event";
import { PaymentSetup } from "../../../../types/paymentSetup";
import { Venue } from "../../../../types/venue";

@Component({
  selector: "app-payment",
  templateUrl: "./payment.component.html",
  styleUrls: ["./payment.component.scss"]
})
export class PaymentComponent implements OnInit {
  eventId: string;
  user: any = {};
  event: Event = new Event();

  loading: boolean = false;

  constructor(
    public auth: AuthService,
    private toasterService: ToasterService,
    private afs: AngularFirestore,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.auth.user.subscribe(user => {
      this.user = user;
      this.setupPage();
    });
  }

  setupPage() {
    this.route.params.subscribe(params => {
      if (params.eventid) {
        this.eventId = params.eventid;
        this.afs
          .collection("events")
          .doc(this.eventId)
          .valueChanges()
          .subscribe(doc => {
            this.event = <Event>doc;
            if (!this.event.paymentSetup) {
              console.log("creating a new payment setup");
              this.event.paymentSetup = new PaymentSetup();
            }
            if (this.event.paymentSetup.useDefaultBanking) {
              this.event.paymentSetup.accountName = this.user.banking.accountName;
              this.event.paymentSetup.accountNumber = this.user.banking.accountNumber;
              this.event.paymentSetup.accountBSB = this.user.banking.accountBSB;
            }
            //this.event.paymentSetup.fibieFee = environment.fibieOptions.fees;

            this.formValidation();
          });
      }
    });
  }

  formValidation() {
    var that = this;
    var forms = document.getElementsByClassName("needs-validation");
    var validation = Array.prototype.filter.call(forms, function(form) {
      form.addEventListener(
        "submit",
        function(event) {
          if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            //toast
            that.displayNotification(
              "Oops!",
              "Make sure you have filled all of the fields correctly.",
              true
            );
          }
          form.classList.add("was-validated");
        },
        false
      );
    });
  }

  async submit(form: NgForm) {
    if (form.valid) {
      this.loading = true;
      this.event.paymentSetup = Object.assign({}, this.event.paymentSetup);
      this.afs
        .collection("events")
        .doc(this.event.id)
        .update(this.event)
        .then(ref => {
          this.loading = false;
          this.displayNotification(
            this.event.name,
            "Payment setup has been saved"
          );
        });
    }
  }

  displayNotification(title: string, msg: string, error: boolean = false) {
    this.toasterService.pop({
      type: error ? "error" : "success",
      title: title,
      body: msg,
      timeout: 2500
    });
  }
}
