import { Component } from "@angular/core";

@Component({
  selector: "fibie-loader",
  template: `
    <div class="linear-activity"><div class="indeterminate"></div></div>
  `,
  styleUrls: ["./loader.component.scss"]
})
export class LoaderComponent {
  constructor() {}
}
