import { Pipe, PipeTransform } from "@angular/core";
import { DatePipe } from "@angular/common";

@Pipe({
  name: "dateSuffix"
})
export class DateSuffixPipe implements PipeTransform {
  transform(seconds): string {
    var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
    d.setUTCSeconds(seconds);
    let suffix = "th",
      day = d.getDate().toString();

    if (day === "1" || day === "21" || day === "31") {
      suffix = "st";
    } else if (day === "2" || day === "22") {
      suffix = "nd";
    } else if (day === "3" || day === "23") {
      suffix = "rd";
    }
    let str =
      new DatePipe("en-AU").transform(d, "EEE MMM d") +
      suffix +
      new DatePipe("en-AU").transform(d, " h:mm a");

    return str;
  }
}
