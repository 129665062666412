export interface Tickets {
  id?: string;
  name: string;
  order: number;
  active: boolean;
  linkedTickets?: any;

  free: boolean;
  description: string;
  quantity: number;
  price: number;
  refundDays?: number;
  thumbnail?: string;
  colorCode?: string;
  showLowStockMessage: boolean;
  showRemainingNumber: boolean;

  scheduled: boolean;
  scheduledMessage: string;
  starts?: any;
  startsTime?: any;
  ends?: any;
  endsTime?: any;
  maximumQuantity?: number;
}

export class Tickets {
  id?: string;
  name: string = "Standard Ticket";
  order: number = 0;
  active: boolean = false;
  linkedTickets?: any = [];

  free: boolean = true;
  description: string = "";
  quantity: number = 0;
  price: number = 0;
  refundDays?: number = null;
  thumbnail?: string = "";
  colorCode?: string = "#6C52A2";

  showLowStockMessage: boolean = true;
  showRemainingNumber: boolean = true;

  scheduled: boolean = false;
  scheduledMessage: string = "Limited Offer";
  starts?: any;
  startsTime?: any;
  ends?: any;
  endsTime?: any;
  maximumQuantity?: number = 0;
}
