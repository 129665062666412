import { Component, OnInit } from "@angular/core";
import { AuthService } from "../../../../core/auth/auth.service";
import { UserBanking } from "../../../../types/user";
import { ToasterService } from "angular5-toaster";

@Component({
  selector: "app-my-banking",
  templateUrl: "./my-banking.component.html",
  styleUrls: ["./my-banking.component.scss"]
})
export class MyBankingComponent implements OnInit {
  user: any = {};
  banking: UserBanking = new UserBanking();

  loading: boolean = false;

  constructor(public auth: AuthService, private toasterService: ToasterService) {}

  ngOnInit() {
    this.auth.user.subscribe(user => {
      this.user = user;
      if (user.banking) this.banking = user.banking;
    });
  }

  save() {
    this.loading = true;

    this.auth
      .updateUserBanking(this.user, this.banking)
      .then(() => {
        this.toasterService.pop({
          type: "success",
          title: "Success",
          body: "You changes are now live",
          timeout: 3500
        });
        this.loading = false;
      })
      .catch(err => {
        this.toasterService.pop({
          type: "error",
          title: "Error saving your details",
          body: err.message,
          timeout: 3500
        });
        this.loading = false;
      });
  }
}
