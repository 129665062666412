import { Component, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { AuthService } from "../../../../core/auth/auth.service";
import { ActivatedRoute, ParamMap, Params, Router } from "@angular/router";
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument
} from "angularfire2/firestore";
import { ToasterModule, ToasterService } from "angular5-toaster";
import { IMyDpOptions, IMyDateModel } from "mydatepicker";

import { FormControl } from "@angular/forms";

import { Event } from "../../../../types/event";
import { Tickets } from "../../../../types/tickets";
import { Venue } from "../../../../types/venue";
//import firebase = require("firebase/app");

import * as _ from "lodash";

@Component({
  selector: "app-ticket-types",
  templateUrl: "./tickets.component.html",
  styleUrls: ["./tickets.component.scss"]
})
export class TicketTypesComponent implements OnInit {
  eventId: string;
  event: Event = new Event();
  tickets: any = [];

  loading: boolean = false;

  public myDatePickerOptions: IMyDpOptions = {
    dateFormat: "dd mmm yyyy",
    editableDateField: false,
    openSelectorOnInputClick: true,
    showTodayBtn: false,
    showClearDateBtn: false
    //disableUntil: { year: 2018, month: 3, day: 15 },
    //disableSince: { year: 2018, month: 9, day: 15 }
  };

  constructor(
    public auth: AuthService,
    private toasterService: ToasterService,
    private afs: AngularFirestore,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.setupPage();
  }

  setupPage() {
    this.route.params.subscribe(params => {
      if (params.eventid) {
        this.eventId = params.eventid;
        this.afs
          .collection("events")
          .doc(this.eventId)
          .valueChanges()
          .subscribe(doc => {
            this.event = <Event>doc;
            this.formValidation();
          });
        this.afs
          .collection(`/events/${this.eventId}/tickets`)
          .valueChanges()
          .subscribe(ticketTypes => {
            if (ticketTypes) this.tickets = ticketTypes;
          });
      }
    });
  }

  formValidation() {
    var that = this;
    var forms = document.getElementsByClassName("needs-validation");
    var validation = Array.prototype.filter.call(forms, function(form) {
      form.addEventListener(
        "submit",
        function(event) {
          if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            //toast
            that.displayNotification(
              "Oops!",
              "Make sure you have filled all of the fields correctly.",
              true
            );
          }
          form.classList.add("was-validated");
        },
        false
      );
    });
  }

  setReservedSeating(e) {
    this.event.reservedSeating = e;
  }

  addCustomField() {
    if (!this.event.customFields) this.event.customFields = [];

    this.event.customFields.push({
      name: "Custom Field",
      type: "text",
      required: true
    });
  }

  removeCustomField(index) {
    this.event.customFields.splice(index, 1);
  }

  addTicketType() {
    let ticket = Object.assign({}, new Tickets());
    this.afs
      .collection(`/events/${this.eventId}/tickets`)
      .add(Object.assign({}, ticket))
      .then(ref => ref.set({ id: ref.id }, { merge: true }));
  }

  copyTicketType(ticket) {
    let cloned = _.clone(ticket);
    console.log(ticket);
    console.log(cloned);
    cloned.id = null;
    cloned.name = cloned.name + " (copy)";
    this.afs
      .collection(`/events/${this.eventId}/tickets`)
      .add(Object.assign({}, cloned))
      .then(ref => {
        ref.set({ id: ref.id }, { merge: true });
        this.displayNotification(
          "Done",
          `Ticket type ${ticket.name} has been cloned`
        );
      });
  }

  removeTicketType(id) {
    this.afs.doc(`/events/${this.eventId}/tickets/${id}`).delete();
  }

  quanityToggle(ticket) {
    ticket.quantity = ticket.quantity ? 0 : 50;
  }

  onDateChanged(e, obj) {
    //console.log("test");
  }

  async submit(form: NgForm) {
    let freeOptions: boolean = false;
    let fromPrice: number = 0;
    let toPrice: number = 0;
    //prettier-ignore
    this.tickets.forEach(ticket => {
      if(ticket.free) {
        freeOptions = true;
      } else {
        if(ticket.price != "0" && fromPrice == 0) fromPrice = ticket.price;
        if(ticket.price != "0" && toPrice == 0) toPrice = ticket.price;
        if(ticket.price != "0" && fromPrice > ticket.price) fromPrice = ticket.price;
        if(ticket.price != "0" && toPrice < ticket.price) toPrice = ticket.price;
      }

    });
    this.event.freeOptions = freeOptions;
    this.event.fromPrice = fromPrice;
    this.event.toPrice = toPrice;
    if (form.valid) {
      this.loading = true;
      this.afs
        .collection("events")
        .doc(this.event.id)
        .update(this.event)
        .then(ref => {
          this.saveTickets();
          this.loading = false;
          this.displayNotification(
            this.event.name,
            "Ticket setup has been saved"
          );
        });
    } else {
      console.log("form validation failed");
    }
  }

  saveTickets() {
    this.tickets.forEach(ticket => {
      this.afs
        .collection(`/events/${this.event.id}/tickets`)
        .doc(ticket.id)
        .update(ticket);
    });
  }

  displayNotification(title: string, msg: string, error: boolean = false) {
    this.toasterService.pop({
      type: error ? "error" : "success",
      title: title,
      body: msg,
      timeout: 2500
    });
  }
}
