import { Component, OnInit, ChangeDetectionStrategy } from "@angular/core";

@Component({
  selector: "app-my-payouts",
  templateUrl: "./my-payouts.component.html",
  styleUrls: ["./my-payouts.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MyPayoutsComponent implements OnInit {
  page: number = 1;
  payments: any = [];
  // payments: any = [
  //   {
  //     description: "Event - The Wiggles 20 December",
  //     amount: 240.4,
  //     date: new Date()
  //   },
  //   {
  //     description: "Event - The Foo Fighters",
  //     amount: 90,
  //     date: new Date()
  //   }
  // ];
  constructor() {}

  ngOnInit() {}
}
