import { Component, OnInit, OnDestroy } from "@angular/core";
import { AngularAgoraRtcService, Stream } from "angular-agora-rtc";
import { Observable } from "rxjs";

import { AuthService } from "../../core/auth/auth.service";

import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument
} from "angularfire2/firestore";

@Component({
  selector: "app-live-stream",
  templateUrl: "./live-stream.component.html",
  styleUrls: ["./live-stream.component.scss"]
})
export class LiveStreamComponent implements OnInit, OnDestroy {
  callItems: Observable<any[]>;
  userItems: Observable<any[]>;

  localStream: Stream;
  remoteCalls: any = [];

  user: any;
  newComment: string;

  comments: any = [];

  showComments: boolean = false;

  sessions: any = [
    {
      name: "How to create events",
      time: "4:45pm AEST"
    },
    {
      name: "Getting creative with Fibie",
      time: "5:45pm AEST"
    },
    {
      name: "Another session",
      time: "5:50pm AEST"
    }
  ];
  constructor(
    private agoraService: AngularAgoraRtcService,
    private afs: AngularFirestore,
    public auth: AuthService
  ) {
    this.getSessionData();
    this.agoraService.createClient();
    this.joinStream();
  }

  getSessionData() {
    let sub = this.afs
      .collection("streams")
      .doc("fibie-demo")
      .valueChanges()
      .subscribe(doc => {
        let adoc = <any>doc;
        if (adoc.comments) {
          this.comments = adoc.comments;
        }
        //sub.unsubscribe();
      });
  }

  addComment() {
    if (this.newComment.length > 0) {
      this.comments.push({
        photoURL: this.user.photoURL
          ? this.user.photoURL
          : "/assets/images/empty_avatar.png",
        displayName: this.user.displayName,
        comment: this.newComment
      });

      this.afs
        .collection("streams")
        .doc("fibie-demo")
        .set({ comments: this.comments }, { merge: true });

      this.newComment = "";
    }
  }

  joinStream() {
    let channel: string = "fibiedemo"; //"-M-934RFio_1_uzacs96"; //firebase "calls" UID

    this.auth.user.subscribe(user => {
      //user.uid
      this.user = user;
      this.agoraService.client.join(null, channel, null, uid => {
        this.subscribeStreams();
      });
    });
  }

  subscribeStreams() {
    // Add
    this.agoraService.client.on("error", err => {
      console.log("Got error msg:", err.reason);
      if (err.reason === "DYNAMIC_KEY_TIMEOUT") {
        this.agoraService.client.renewChannelKey(
          "",
          () => {
            console.log("Renew channel key successfully");
          },
          err => {
            console.log("Renew channel key failed: ", err);
          }
        );
      }
    });

    this.agoraService.client.on("stream-added", evt => {
      const stream = evt.stream;
      this.agoraService.client.subscribe(stream, err => {
        console.log("Subscribe stream failed", err);
      });
    });

    // Add
    this.agoraService.client.on("peer-online", evt => {
      console.log("peer online");
    });

    this.agoraService.client.on("stream-subscribed", evt => {
      const stream = evt.stream;
      if (!this.remoteCalls.includes(`agora_remote${stream.getId()}`))
        this.remoteCalls.push(`agora_remote${stream.getId()}`);
      setTimeout(() => stream.play(`agora_remote${stream.getId()}`), 2000);
    });

    // Add
    this.agoraService.client.on("stream-removed", evt => {
      const stream = evt.stream;
      stream.stop();
      this.remoteCalls = this.remoteCalls.filter(
        call => call !== `#agora_remote${stream.getId()}`
      );
      console.log(`Remote stream is removed ${stream.getId()}`);
    });

    // Add
    this.agoraService.client.on("peer-leave", evt => {
      const stream = evt.stream;
      if (stream) {
        stream.stop();
        this.remoteCalls = this.remoteCalls.filter(
          call => call === `#agora_remote${stream.getId()}`
        );
        console.log(`${evt.uid} left from this channel`);
      }
    });
  }

  ngOnInit() {}
  ngOnDestroy() {
    this.agoraService.client.leave(() => {
      // this.localStream.stop();
      // this.localStream.close();
    });
  }
}
