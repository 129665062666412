import { Component, OnChanges, Input } from "@angular/core";
import { FormsModule } from "@angular/forms";
import * as _ from "lodash";
import * as moment from "moment";

declare var lightGallery: any;
declare var $: any;
declare const window: any;

@Component({
  selector: "app-property-details",
  templateUrl: "./property-details.component.html",
  styleUrls: ["./property-details.component.scss"]
})
export class PropertyDetailsComponent implements OnChanges {
  @Input() property: any;
  @Input() rates: any;
  @Input() reviews: any;

  expandedView: boolean = true;

  constructor() {}

  ngOnChanges() {}

  showGallery() {
    let imgs = [];
    for (let i = 0; i < this.property.images.length; i++) {
      imgs.push({
        src: this.property.images[i].path,
        thumb: this.property.images[i].path.replace(".jpg", "_thumb.jpg"),
        subHtml: this.property.images[i].description
      });
    }

    //prettier-ignore
    if(window.lgData[document.getElementById('lightgallery').getAttribute('lg-uid')])window.lgData[document.getElementById('lightgallery').getAttribute('lg-uid')].destroy(true);

    lightGallery(document.getElementById("lightgallery"), {
      download: false,
      thumbnail: true,
      dynamic: true,
      dynamicEl: imgs
    });
  }

  clear() {
    this.property = null;
    this.rates = null;
    this.reviews = null;
  }

  getReviewBar(num) {
    let percentage: number = 0;

    if (this.reviews) {
      percentage =
        (_.filter(this.reviews, function(o) {
          if (o.rating == num) return o;
        }).length /
          this.reviews.length) *
        100;
    }
    return percentage + "%";
  }

  isWeekend(d) {
    return new Date(d).getDay() % 6 == 0;
  }

  counter(i) {
    return new Array(Math.floor(i));
  }

  sanitize(str) {
    return str.replace(/(?:\r\n|\r|\n)/g, "<br>");
  }
}
